import classNames from 'classnames';
import {
    PledgeCalculationMethodTypeCodeEnum,
    PledgeInfoRead,
    ProjectStateCodeEnum,
} from '../../../../../../server';

import './PledgeData.less';
import { LinkButton2 } from '../../../../../../components/buttons/linkButton2/linkButton2';
import { Icon } from 'antd';
import {IconCashRegisterSolid, IconReply, IconSyncSolid} from '../../../../../../components/icons';
import { DataDisplayItem, DataDisplayItemType } from '../../../../../../components/dataDisplay';
import { useCreatePledgeMutation, useUpdatePledgeMutation } from '../../../api/projects.api';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
    loadSimpleOrder,
    recalculateSimpleOrderRequiredPledge,
    setProjectPledge
} from '../../../simpleOrders/reducers/simpleOrder.reducer';
import PledgeHistoryPopover from './PledgeHistoryPopover';
import { push } from 'connected-react-router';
import { showNotification } from '../../../../../../components/notification/showNotification';
import {loadEntity, recalculateProjectRequiredPledge} from '../../reducers/project.reducer';
import { IRootState } from '../../../../../../shared/reducers';
import { canViewFinancialDataSelector } from '../../../../../../shared/reducers/permissions.reducer';
import {showConfirm} from "../../../../../../components/confirm/showConfirm";
import {useIntl} from "react-intl";
import {formatMoney, formatMoneyWithFixedDecimalScale} from "../../../../../../shared/util/formatMoney";
import {isDefined} from "../../../../../../shared/util/utils";

interface Props {
    data: PledgeInfoRead | undefined;
    debtSum: number;
    className?: string;
    state?: ProjectStateCodeEnum;
    style?: CSSProperties;
    isSimpleOrder: boolean;
    cachedRequiredPledgeSum?: number;
    disabled?: boolean;
}

const NO_PLEDGE_DESCRIPTION = 'Вещевого нет';

const PledgeData = ({ data, className, debtSum, state, style, isSimpleOrder, cachedRequiredPledgeSum, disabled }: Props) => {
    const [createPledge, { isSuccess: creatingSuccess, isError: createError }] = useCreatePledgeMutation();
    const [updatePledge, { isSuccess: updatingSuccess, isError: updateError }] = useUpdatePledgeMutation();
    const dispatch = useAppDispatch();
    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const pledgeCalculationMethodType = useAppSelector((state: IRootState)=> state.businessAccountPreferences.preferences?.pledgeCalculationMethodType);
    const intl = useIntl();

    const pledgesModuleEnabled = useAppSelector(
        (state: IRootState) => state.businessAccountPreferences.preferences?.modules.pledgesModuleEnabled
    );

    const { id, businessAccountId } = useParams<{ id: string; businessAccountId: string }>();

    const payHandler = () => {
        if (data) {
            dispatch(setProjectPledge(data));
            dispatch(push(`/${businessAccountId}/projects/${isSimpleOrder ? 'simpleOrders' : 'production'}/${id}/pay/?tab=description`));
        }
    };

    useEffect(() => {
        if ((creatingSuccess || updatingSuccess) && id && businessAccountId) {
            dispatch(isSimpleOrder ? loadSimpleOrder(+businessAccountId, +id) : loadEntity(+businessAccountId, +id));
        }
    }, [businessAccountId, creatingSuccess, dispatch, id, isSimpleOrder, updatingSuccess]);

    const [pledge, setPledge] = useState(
        data?.audits?.length
            ? {
                  sum: data.audits.at(-1)!.sum,
                  description: data.audits.at(-1)!.description,
              }
            : { sum: 0, description: undefined }
    );

    useEffect(() => {
        setPledge(
            data?.audits?.length
                ? {
                      sum: data.audits.at(-1)!.sum,
                      description: data.audits.at(-1)!.description,
                  }
                : { sum: 0, description: undefined }
        );
    }, [data]);

    const blurHandler = useCallback(
        (value: string | number | undefined, param: 'sum' | 'description') => {
            if (param === 'sum' && value && typeof value === 'number' && value < 0) {
                showNotification('error', 'Залог не может быть отрицательным');
                return;
            }
            if (data) {
                updatePledge({
                    pledgeId: data.id,
                    projectId: +id,
                    data: {
                        sum: param === 'sum' && (typeof value === 'number' || value === undefined) ? value ?? 0 : pledge.sum,
                        description:
                            param === 'description' && (typeof value === 'string' || value === undefined) ? value : pledge.description,
                    },
                });
            } else {
                createPledge({
                    projectId: +id,
                    data: {
                        sum: param === 'sum' && value ? +value : 0,
                        description: param === 'description' && value && !!value.toString().length ? value.toString() : undefined,
                    },
                });
            }
        },
        [createPledge, data, id, pledge, updatePledge]
    );

    const resetHandler = useCallback(() => {
        if (data) {
            updatePledge({
                pledgeId: data.id,
                projectId: +id,
                data: {
                    sum: 0,
                    description: undefined,
                },
            });
        }
    }, [data, id, updatePledge]);

    if (!data && !pledgesModuleEnabled) return null;

    const createPledgeBlock = () => {

        const className = cachedRequiredPledgeSum === 0 ? undefined : ((cachedRequiredPledgeSum||0) > pledge.sum ? 'requiredPledgeSum-problem' : 'requiredPledgeSum-success');

        const pledgeCalculationBlock = pledgesModuleEnabled && isDefined(cachedRequiredPledgeSum) && pledgeCalculationMethodType !== PledgeCalculationMethodTypeCodeEnum.DISABLED && (
            <div className={'requiredPledgeSum'}>
                <div className={className}>Рассчитанный залог {formatMoney(cachedRequiredPledgeSum)}</div>
                <LinkButton2
                    style={{marginLeft: 8}}
                    icon={<Icon style={{ color: '#04b0f1', fontSize: 18 }} component={IconSyncSolid} />}
                    onClick={()=>{
                        dispatch(isSimpleOrder ? recalculateSimpleOrderRequiredPledge(+businessAccountId, +id) : recalculateProjectRequiredPledge(+businessAccountId, +id));
                    }}
                />
                {
                    (cachedRequiredPledgeSum > pledge.sum) && <LinkButton2
                        style={{marginLeft: 2}}
                        label={pledge.sum ? 'Довнести' : 'Внести'}
                        onClick={async ()=>{
                            const sum = cachedRequiredPledgeSum - pledge.sum;
                            const yes = await showConfirm(intl, <>Зафиксировать внесение {formatMoney(sum)} залога?</>);
                            if(yes){
                                blurHandler(cachedRequiredPledgeSum, 'sum')
                            }
                        }}
                    />
                }
            </div>
        );
        return pledgeCalculationBlock;
    };

    return (
        <div className={classNames('rr-pledge', className)} style={style}>
            <div className="rr-pledge-header">
                <span>{canViewFinancialData ? 'Залог' : 'Вещевой залог'}</span>
                <PledgeHistoryPopover items={data?.audits} />
            </div>
            <div className="rr-pledge-data">
                {canViewFinancialData && (
                    <span className="rr-pledge-field">
                        <DataDisplayItem
                            type={DataDisplayItemType.Money}
                            value={pledge.sum}
                            onChange={(v) => setPledge({ ...pledge, sum: v ?? 0 })}
                            editable={!disabled && pledgesModuleEnabled}
                            onBeforeBlur={(value) => blurHandler(value, 'sum')}
                            hint={createPledgeBlock()}
                        />
                    </span>
                )}
                <span className="rr-pledge-field">
                    <DataDisplayItem
                        type={DataDisplayItemType.String}
                        value={pledge.description}
                        onChange={(v) => setPledge({ ...pledge, description: v })}
                        emptyText={canViewFinancialData ? NO_PLEDGE_DESCRIPTION : 'Нет'}
                        editable={!disabled && pledgesModuleEnabled}
                        isError={updateError || createError}
                        onBeforeBlur={(value) => blurHandler(value, 'description')}
                        maxLength={255}
                    />
                </span>
            </div>
            {(pledge.sum > 0 || !!pledge.description) && (
                <div className="rr-pledge-actions">
                    {(pledge.sum > 0 || !!pledge.description) && (
                        <LinkButton2
                            disabled={!data?.audits?.length}
                            icon={<Icon style={{ color: '#34bfa3', fontSize: 21 }} component={IconReply} />}
                            label="Вернуть"
                            onClick={resetHandler}
                        />
                    )}
                    {pledge.sum > 0 && state !== ProjectStateCodeEnum.CANCELED && state !== ProjectStateCodeEnum.DRAFT && (
                        <LinkButton2
                            disabled={debtSum <= 0 || !data || !data.audits?.length || data.audits[data.audits.length - 1].sum <= 0}
                            icon={<Icon style={{ color: '#ffcd43', fontSize: 21 }} component={IconCashRegisterSolid} />}
                            label="Оплатить залогом"
                            onClick={payHandler}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default PledgeData;
