import React, { FC, useEffect, useState } from 'react';
import { EntityDrawer } from '../../../../components/v2/entityDrawer/EntityDrawer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
    addInstanceFromScanner,
    reloadAllTimetables,
    setDefaultRentPeriodForSimpleOrder,
    setRenter,
} from '../reducers/operationForm.reducer';
import { SimpleOrderPageTopBlock } from './comonents/simpleOrderPageTopBlock/simpleOrderPageTopBlock';
import moment from 'moment';
import { SimpleOrderPageBottomBlock } from './comonents/simpleOrderPageBottomBlock/simpleOrderPageBottomBlock';
import {
    NomenclatureEntityTypeCodeEnum,
    ProjectInfoRead
} from '../../../../server';
import { OrderElementsListEdit } from './orderElementsListEdit';
import { useLocation } from 'react-router-dom';
import { OrderElementsList } from './orderElementsList';
import { NomenclaturesList } from '../operationModule/nomenclaturesTab/list/nomenclaturesList';
import { businessAccountIdSelector } from '../../../../shared/reducers/system.reducer';
import { replace } from 'connected-react-router';
import { RichTextView } from '../../../../components/richTextView/RichTextView';
import { LocalizationEnum, localize } from '../../../../localization';
import { FormValue } from '../../../../components';
import { Card, Col, Row } from 'antd';
import classNames from 'classnames';
import { MoneyUtils } from '../../../../core/utils/moneyUtils';
import { barcodeScanEventTarget, getStore } from '../../../../../index';
import { BarcodeScanEvent } from '../../../../shared/BarcodeScanEvent';
import { scan, ScanFnProps } from '../operationModule/operationFormScanUtils';
import { OperationModuleTabsEnum } from '../../../../shared/constants/tabEnums';
import { showScanInstanceSuccessNotification, showScanNomenclatureSuccessNotification } from '../../../../shared/reducers/scanner.reducer';
import Alert from "../../../../components/alert/alert";
import {IRootState} from "../../../../shared/reducers";

interface Props {
    orderEntity?: ProjectInfoRead;
    createOperation?: () => void;
    orderElementsIsNowEditing?: boolean;
}

const gridName = 'simpleOrder-elements';

export const OrderElements: FC<Props> = ({ orderEntity, createOperation, orderElementsIsNowEditing }) => {
    const [nomenclaturesDrawerVisible, setNomenclaturesDrawerVisible] = useState(false);
    const operationFormState = useAppSelector((state:IRootState) => state.operationForm);
    const dispatch = useAppDispatch();
    let isSimpleOrderOperationStart = operationFormState.typeCode && operationFormState.isSimpleOrder;
    if (orderElementsIsNowEditing !== undefined) isSimpleOrderOperationStart = orderElementsIsNowEditing;
    const startDate = moment(isSimpleOrderOperationStart ? operationFormState.rentPeriodStartDate : orderEntity?.startDate);
    const endDate = moment(isSimpleOrderOperationStart ? operationFormState.rentPeriodEndDate : orderEntity?.targetFinishDate);
    const costOfExpenses = orderEntity?.costOfExpenses || 0;
    const costOfRent = isSimpleOrderOperationStart ? operationFormState.finalTotalPrice || 0 : orderEntity?.costOfRent || 0;
    const taxRate = (isSimpleOrderOperationStart ? operationFormState.taxRate : orderEntity?.taxRate) || 0;
    const taxBaseType = isSimpleOrderOperationStart ? operationFormState.taxBaseType : orderEntity?.taxBaseType;
    const comment = orderEntity?.comment;
    const renterId = isSimpleOrderOperationStart ? operationFormState.renterId : orderEntity?.renterId;
    const taxAmount = isSimpleOrderOperationStart
        ? taxBaseType
            ? MoneyUtils.calculatePriceWithTaxes(costOfRent + costOfExpenses, taxRate, taxBaseType) - (costOfRent + costOfExpenses)
            : 0
        : orderEntity
        ? orderEntity.actualSumAfterTaxes - orderEntity.actualSumBeforeTaxes
        : 0;
    const totalPrice = (isSimpleOrderOperationStart ? costOfRent + costOfExpenses + taxAmount : orderEntity?.actualSumAfterTaxes) || 0;
    const acceptedPaymentsSum = orderEntity?.acceptedPaymentsSum || 0;
    const acceptedPaymentsCount = orderEntity?.acceptedPaymentsCount;
    const debtSum = (isSimpleOrderOperationStart ? totalPrice - acceptedPaymentsSum : orderEntity?.debtSum) || 0;
    const actualSumAfterTaxes = orderEntity?.actualSumAfterTaxes || 0;
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const description = orderEntity?.description;
    const pledge = orderEntity?.pledge;
    const addKitMembersWhenInstanceScan = useAppSelector((state) => state.userSettings.addKitMembersWhenInstanceScan);
    const cachedRequiredPledgeSum = orderEntity?.cachedRequiredPledgeSum;

    const data = {
        renterId,
        startDate,
        endDate,
        comment,
        taxRate,
        taxBaseType,
        taxAmount,
        totalPrice,
        acceptedPaymentsSum,
        acceptedPaymentsCount,
        debtSum,
        costOfRent,
        costOfExpenses,
        actualSumAfterTaxes,
        description,
        pledge,
        cachedRequiredPledgeSum
    };

    const location = useLocation();
    const rootPath = orderEntity ? (location.pathname as any) : `/${businessAccountId}/order`;
    //const orderRecalculateShiftsCount = useAppSelector((storeState)=>storeState.userSettings.recalculateShiftsCount['simpleOrder']);

    useEffect(() => {
        const instanceAddedShowMessage = () => {
            dispatch(showScanInstanceSuccessNotification());
        };

        const nomenclatureAddedShowMessage = (nomenclatureType: NomenclatureEntityTypeCodeEnum.KIT | NomenclatureEntityTypeCodeEnum.VARIANT | NomenclatureEntityTypeCodeEnum.PRODUCT, playSound: boolean) => {
            getStore().dispatch(showScanNomenclatureSuccessNotification(nomenclatureType, playSound));
        };

        const onBarcodeScanEvent = async (e: CustomEvent<BarcodeScanEvent>) => {
            const addInstanceFromScannerFn: ScanFnProps['addInstanceFromScanner'] = (instanceId, entity, nomenclature) => {
                getStore().dispatch(addInstanceFromScanner(instanceId, entity, nomenclature));
            };

            void scan({
                tab: OperationModuleTabsEnum.PRODUCTS,
                operationTypeCode: operationFormState.typeCode,
                code: e.detail.code,
                businessAccountId: businessAccountId,
                instanseAddedShowMessage: instanceAddedShowMessage,
                nomenclatureAddedShowMessage: nomenclatureAddedShowMessage,
                equipmentFilteredEntities: null,
                elements: operationFormState.elements.entities,
                timeTables: operationFormState.timeTables,
                rentPeriodStartDate: operationFormState.rentPeriodStartDate,
                rentPeriodEndDate: operationFormState.rentPeriodEndDate,
                addInstanceFromScanner: addInstanceFromScannerFn,
                addKitMembersWhenInstanceScan: addKitMembersWhenInstanceScan,
            });
        };

        if (nomenclaturesDrawerVisible) barcodeScanEventTarget.addListener(onBarcodeScanEvent);
        return () => {
            if (nomenclaturesDrawerVisible) barcodeScanEventTarget.removeListener(onBarcodeScanEvent);
        };
    }, [nomenclaturesDrawerVisible, addKitMembersWhenInstanceScan]);

    return (
        <>
            <div style={{ backgroundColor: '#FFFFFF', cursor: 'default' }}>
                <div style={{ padding: 32 }}>
                    {
                        operationFormState.creationError && (
                            <Alert
                                message={'Ошибка создания операции'}
                                description={operationFormState.creationError.message}
                                type="error"
                                showIcon
                                closable
                                style={{ marginBottom: 30 }}
                            />
                        )
                    }
                    <SimpleOrderPageTopBlock
                        dates={[data.startDate, data.endDate]}
                        onDatesChanged={(dates, recalculateShiftCount) => {
                            if (dates && dates[0] && dates[1]) {
                                dispatch(setDefaultRentPeriodForSimpleOrder(dates[0], dates[1], recalculateShiftCount));
                                dispatch(reloadAllTimetables());
                            }
                        }}
                        renterId={data.renterId}
                        orderId={orderEntity?.id}
                        order={orderEntity}
                        onRenterChange={(id, name, discount) => {
                            dispatch(setRenter(id, name, discount));
                        }}
                        totalPrice={data.totalPrice}
                        debtSum={data.debtSum}
                        isNowEditing={orderElementsIsNowEditing === true}
                    />
                    {!isSimpleOrderOperationStart && orderEntity && (
                        <OrderElementsList gridName={gridName} parentType={'project'} parentId={orderEntity.id} location={location} />
                    )}
                    {isSimpleOrderOperationStart && (
                        <OrderElementsListEdit
                            location={location}
                            parentType={'project'}
                            parentId={0}
                            gridName={gridName}
                            addElements={() => {
                                dispatch(replace(location.pathname));
                                setNomenclaturesDrawerVisible(true);
                            }}
                            parentProjectEntity={orderEntity || undefined}
                            createOperation={createOperation}
                        />
                    )}
                </div>
                <SimpleOrderPageBottomBlock
                    orderId={orderEntity?.id}
                    comment={data.comment}
                    taxRate={data.taxRate}
                    taxBaseType={data.taxBaseType}
                    taxAmount={data.taxAmount}
                    totalPrice={data.totalPrice}
                    acceptedPaymentsSum={data.acceptedPaymentsSum}
                    acceptedPaymentsCount={data.acceptedPaymentsCount}
                    debtSum={data.debtSum}
                    orderEntity={orderEntity}
                    costOfRent={data.costOfRent}
                    costOfExpenses={data.costOfExpenses}
                    pledge={data.pledge}
                    state={orderEntity?.stateCode}
                    cachedRequiredPledgeSum={data.cachedRequiredPledgeSum}
                />
            </div>

            <Card className={'rr-simpleOrderPage-descriptionBlock'}>
                <Row gutter={0} type={'flex'}>
                    <Col span={24} lg={12} className={classNames('rr-simpleOrderPage-descriptionBlock-description')}>
                        <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}</div>
                        <div className={'rr-text'}>
                            <FormValue
                                value={data.description && <RichTextView value={data.description} />}
                                emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)}
                            />
                        </div>
                    </Col>
                    <Col span={24} lg={12} className={classNames('rr-simpleOrderPage-descriptionBlock-comment')}>
                        <div className={'rr-label'}>{localize(LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT)}</div>
                        <div className={'rr-text'}>
                            <FormValue
                                value={data.comment}
                                emptyText={localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_SPECIFIED__M)}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>

            <EntityDrawer
                visible={nomenclaturesDrawerVisible}
                width={'70vw'}
                isLoading={false}
                title={'Добавление в заказ'}
                placement={'left'}
                onClose={() => {
                    setTimeout(() => {
                        dispatch(replace(location.pathname));
                    }, 100);
                }}
                onStartClose={() => {
                    setNomenclaturesDrawerVisible(false);
                }}
                destroyOnClose={true}
            >
                {/* два режима, когда добавляем из создания и из редактирования*/}
                <NomenclaturesList rootPath={rootPath} />
            </EntityDrawer>
        </>
    );
};
