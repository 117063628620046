import { CSSProperties, ReactElement, ReactNode } from 'react';
import { DataDisplayItemType } from './dataDisplayItemType';
import {
    InstanceRecord,
    PersonFullName,
    PricingSchemeExternalRepresentationObj,
    RentStateCodeEnum,
    SocialNetworkLinkAbstractInfo,
} from '../../server';
import { DataDisplayState } from './dataDisplayState';

export enum DataDisplayItemTheme {
    DARK = 'dark',
    GREY = 'grey',
}

export type DateIntervalHighLight = ['start' | 'end'] | ['start' | 'end', 'start' | 'end'];

export interface DataDisplayBaseProps {
    id?: string;
    type: DataDisplayItemType;
    label?: string | ReactElement;
    emptyValueText?: string | ReactElement;
    state?: DataDisplayState | false | null | undefined | 0;
    labelClassName?: string;
    valueClassName?: string;
    theme?: DataDisplayItemTheme;
    paddings?: boolean;
    style?: CSSProperties;
    hint?: string | ReactNode;
}

export interface DataDisplayStringProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.String;
    value?: string | null;
    editable?: boolean;
    isError?: boolean;
    extraClassNames?: string[];
    onChange?: (value?: string) => void;
    onBeforeBlur?: (value: string | undefined) => Promise<boolean> | void;
    emptyText?: string;
    maxLength?: number;
}

export interface DataDisplayBooleanProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Boolean;
    value?: boolean | null;
    values: [string, string];
}

export interface DataDisplayNumberProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Number;
    value?: number | null;
}

export interface DataDisplayCommentProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Comment;
    value?: string;
}

export interface DataDisplayEmailProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.EMail;
    value?: string;
}

export interface DataDisplayPhoneProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Phone;
    value?: string;
}

export interface DataDisplayUserProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.User;
    value?: {
        baId?: number;
        name?: PersonFullName;
        id?: number;
    };
}

export interface DataDisplayDateProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Date;
    value?: Date | string;
    utc?: boolean;
}

export interface DataDisplayDateWithTimeProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.DateWithTime;
    value?: Date | string;
    utc?: boolean;
}

export interface DataDisplayDateWithTimeIntervalProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.DateWithTimeInterval;
    value?: {
        from?: Date | string;
        to?: Date | string;
    };
    highlight?: DateIntervalHighLight;
}

export interface DataDisplayMoneyProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Money;
    value?: number;
    rentType?: RentStateCodeEnum.DRAFT;
    editable?: boolean;
    onChange?: (value?: number) => void;
    onBeforeBlur?: (value: number | undefined) => Promise<boolean> | void;
    max?: number;
}

export interface DataDisplayPriceProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Price;
    value?: number;
    pricingSchemeData?: {
        pricingScheme: PricingSchemeExternalRepresentationObj;
        shiftCount: number;
        basePrice: number;
    };
}

export interface DataDisplayCounterpartyProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Counterparty;
    value?: {
        baId?: number;
        name?: string;
        id?: number;
    };
}

export interface DataDisplayContactProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Counterparty;
    value?: {
        baId?: number;
        name?: string;
        id?: number;
    };
}

export interface DataDisplayProjectProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Project;
    value?: {
        baId?: number;
        name?: string;
        id?: number;
        onClick?: () => void;
    };
}

export interface DataDisplayProductProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Product;
    value?: {
        baId?: number;
        productName?: string;
        productId?: number;
        variantName?: string;
        variantId?: number;
    };
}

export interface DataDisplayInstancesListProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.InstancesList;
    value?: InstanceRecord[];
}

export interface DataDisplayIconTextProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.IconText;
    value?: {
        icon: ReactNode;
        text: ReactNode;
    };
}

export interface DataDisplayCustomProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Custom;
    value: ReactNode;
}

export interface DataDisplayLinkProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Link;
    value?: {
        text?: string;
        link: string;
        isRoute?: boolean;
        onClick?: () => void;
    };
}

export interface DataDisplaySocialNetworkLinkProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.SocialNetworkLink;
    value?: SocialNetworkLinkAbstractInfo;
}

export type DrawerValue = {
    text: string;
    onClick: Function;
};

export interface DataDisplayDrawerProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.Drawer;
    value?: DrawerValue | DrawerValue[] | undefined;
}

export interface DataDisplayRichTextProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.RichText;
    value?: string;
}

export interface DataDisplayPricingSchemeProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.PricingScheme;
    pricingScheme?: PricingSchemeExternalRepresentationObj;
    pricePerShift?: number;
}
export interface DataDisplayDurationProps extends DataDisplayBaseProps {
    type: DataDisplayItemType.ShiftDuration;
    durationInMinutes?: number;
    hasOwnShiftLength?: boolean;
}

export type DataDisplayItemProps =
    | DataDisplayStringProps
    | DataDisplayNumberProps
    | DataDisplayBooleanProps
    | DataDisplayCommentProps
    | DataDisplayEmailProps
    | DataDisplayPhoneProps
    | DataDisplayUserProps
    | DataDisplayDateProps
    | DataDisplayDateWithTimeIntervalProps
    | DataDisplayDateWithTimeProps
    | DataDisplayMoneyProps
    | DataDisplayPriceProps
    | DataDisplayCounterpartyProps
    | DataDisplayProjectProps
    | DataDisplayProductProps
    | DataDisplayInstancesListProps
    | DataDisplayIconTextProps
    | DataDisplayLinkProps
    | DataDisplaySocialNetworkLinkProps
    | DataDisplayCustomProps
    | DataDisplayDrawerProps
    | DataDisplayRichTextProps
    | DataDisplayPricingSchemeProps
    | DataDisplayDurationProps;
