import React from 'react';
import { FormFieldsGroup, FormItemType, SelectItem } from '../../../../../../components/dynamicForm/DynamicForm';
import { ContactLinkObjWrite, CounterpartyActivityTypeCodeEnum, RenterStateCodeEnum, UserRecord } from '../../../../../../server';
import debounce from 'lodash/debounce';
import {ContactsEditor, ContactsEditorProps} from "../../../../../../components/contactsEditor/contactsEditor";
import { getStoreState } from '../../../../../../../index';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateField } from '../../../../../../shared/util/validateField';
import { EntityRemoteSelect } from '../../../../../../components/select/EntityRemoteSelect';
import { LocalizationEnum, localize } from '../../../../../../localization';
import { CounterpartyCategoriesSelect } from '../categories/CounterpartyCategoriesSelect';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../../../shared/util/permissionUtils';
import { createHiddenField } from '../../../../../../components/modalForm/utils';
import {
    pledgesModuleEnabledSelector,
    subrentModuleEnabledSelector
} from '../../../../../../shared/reducers/businessAccountPreferences.reducer';
import { Select } from 'antd';
import UserDetailedOption from '../../../../../../components/v2/detailedSelect/UserDetailedOption';

const isStateCodeDisabled = (getFieldValue: Function) => getFieldValue('id');

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('counterpartyType'),
            createHiddenField('renterEdit'),
            createHiddenField('hasSubrents'),
            createHiddenField('hasProjects'),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                required: true,
                type: FormItemType.String,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('renterEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'renter',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        localize(LocalizationEnum.PAGE__RENTERS__CARD__SHORT_NAME_ALREADY_USE),
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const ignoreCurrentId = form.getFieldValue('renterEdit') ? form.getFieldValue('id') : undefined;
                    validateField(
                        'renter',
                        fieldName,
                        value,
                        undefined,
                        ignoreCurrentId,
                        localize(LocalizationEnum.PAGE__RENTERS__CARD__FULL_NAME_ALREADY_USED),
                        cb
                    );
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
                id: 'stateCode',
                type: FormItemType.Select,
                defaultValue: RenterStateCodeEnum.ACTIVE,
                required: true,
                values: (form: WrappedFormUtils) => {
                    const values: SelectItem[] = [
                        {
                            name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__NEW),
                            value: RenterStateCodeEnum.NEW,
                        },
                        {
                            name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__ACTIVE),
                            value: RenterStateCodeEnum.ACTIVE,
                        },
                    ];

                    const isFieldDisabled = isStateCodeDisabled(form.getFieldValue);
                    if (isFieldDisabled) {
                        values.push({
                            name: localize(LocalizationEnum.ASPECT__STATE_CODE__RENTER__BLOCKED),
                            value: RenterStateCodeEnum.BLOCKED,
                        });
                    }

                    return values.map((item) => {
                        return item
                            ? {
                                  name: (
                                      <>
                                          <div className={`rr-dot rr-status-bg-` + item.value} style={{ marginRight: '10px' }}></div>
                                          <span className="calendar-multi-select">{item.name}</span>{' '}
                                      </>
                                  ),
                                  value: item.value,
                              }
                            : undefined;
                    });
                },
                disabled: (getFieldValue) => isStateCodeDisabled(getFieldValue),
            },
            {
                label: 'Вид деятельности',
                placeholder: 'Выберите вид деятельности',
                id: 'activityTypeCode',
                type: FormItemType.Select,
                required: true,
                values: (form: WrappedFormUtils) => {
                    if (form.getFieldValue('counterpartyType') === 'renter') {
                        return [
                            !form.getFieldValue('hasSubrents')
                                ? {
                                      name: 'Арендатор',
                                      value: CounterpartyActivityTypeCodeEnum.RENTERONLY,
                                  }
                                : undefined,
                            {
                                name: 'Арендатор и поставщик',
                                value: CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                            },
                        ].filter((i) => i !== undefined);
                    } else if (form.getFieldValue('counterpartyType') === 'supplier') {
                        return [
                            !form.getFieldValue('hasProjects')
                                ? {
                                      name: 'Поставщик',
                                      value: CounterpartyActivityTypeCodeEnum.SUPPLIERONLY,
                                  }
                                : undefined,
                            {
                                name: 'Арендатор и поставщик',
                                value: CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                            },
                        ].filter((i) => i !== undefined);
                    } else {
                        return [
                            !form.getFieldValue('hasSubrents')
                                ? {
                                      name: 'Арендатор',
                                      value: CounterpartyActivityTypeCodeEnum.RENTERONLY,
                                  }
                                : undefined,
                            !form.getFieldValue('hasProjects')
                                ? {
                                      name: 'Поставщик',
                                      value: CounterpartyActivityTypeCodeEnum.SUPPLIERONLY,
                                  }
                                : undefined,
                            {
                                name: 'Арендатор и поставщик',
                                value: CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                            },
                        ].filter((i) => i !== undefined);
                    }
                },
                visible: () => {
                    return subrentModuleEnabledSelector(getStoreState()) === true;
                },
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE,
                id: 'assigneeId',
                type: FormItemType.Component,
                component: EntityRemoteSelect,
                componentProps: {
                    className: 'rr-select-detailed',
                    operationName: 'listUsersSimple',
                    nameField: 'shortName',
                    renderer: (option: { id: number; name: string; data: UserRecord }) => {
                        return (
                            <Select.Option key={option.id}>
                                <UserDetailedOption item={option.data} getKey={(item) => item.id} />
                            </Select.Option>
                        );
                    },
                },
                placeholder: 'Выберите пользователя',
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__CATEGORIES,
                id: 'categoryIds',
                type: FormItemType.Component,
                component: CounterpartyCategoriesSelect,
                placeholder: localize(LocalizationEnum.ASPECT__FORMS__PLACEHOLDER__SELECT_CATEGORY),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__IMAGE,
                id: 'imageData',
                type: FormItemType.UploadImage,
                imagesMaxCount: 1,
                infoMessage: localize(LocalizationEnum.ASPECT__FORMS__IMAGE_FORMAT_RESCTRICTIONS_STRING),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DEFAULT_DISCOUNT,
                id: 'defaultDiscount',
                type: FormItemType.Discount,
                required: true,
                defaultValue: 0,
                visible: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return getFieldValue('activityTypeCode') !== CounterpartyActivityTypeCodeEnum.SUPPLIERONLY && canViewFinancialData(p);
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                label: 'Беззалоговый лимит',
                id: 'unsecuredLimitAmount',
                type: FormItemType.Money,
                required: true,
                defaultValue: 0,
                step: 50,
                visible: (getFieldValue) => {
                    const state = getStoreState();
                    const pledgesModuleEnabled = pledgesModuleEnabledSelector(state) || false;
                    const p = getStoreState().permissions.permissions;
                    return pledgesModuleEnabled && (getFieldValue('activityTypeCode') === CounterpartyActivityTypeCodeEnum.RENTERONLY || getFieldValue('activityTypeCode') === CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER) && canViewFinancialData(p);
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        fields: [
            {
                id: 'contactLinks',
                type: FormItemType.Component,
                component: ContactsEditor,
                componentProps: (form: WrappedFormUtils):Partial<ContactsEditorProps>=>{
                    return {
                        withMainContact: true,
                        required: true
                    };
                },
                validationRules: [
                    {
                        validator: (rule, value: ContactLinkObjWrite[] | undefined, cb) => {
                            if(!value || value.length === 0) cb('У контрагента должен быть хотя бы один контакт');
                            else if(value && value.some((item) => item.isMainContact && !item.contactId)) cb('Основной контакт не выбран');
                            else if(value && value.every((item) => !item.isMainContact)) cb('Основной контакт не выбран');
                            else if(value && value.some((item) => !item.contactId && item.position)) cb('Контакт не выбран');
                            else cb();
                        },
                    },
                ],
                defaultValue: [{position: '', isMainContact: true}]
            },
        ],
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];
