import { useAppSelector } from '../../../../../store/hooks';
import {businessAccountIdSelector, getCurrentServerTime} from '../../../../../shared/reducers/system.reducer';
import { WorkPlanningsCreateModal } from './workPlanningsCreateModal';
import { LocalizationEnum, localize } from '../../../../../localization';
import React, { FunctionComponent, memo } from 'react';
import { ProjectInfoRead } from '../../../../../server';
import { WorkPlanningCreateModalFormData } from './workPlanningsCreateModal.types';
import { ModalFormNEWProps } from '../../../../../components/modalForm/ModalFormNEW';
import { EntityGridRootPath } from '../../../../../components/page/entityGrid/types/params';
import { getDefaultStateForCreateModal } from '../../utils/getWorkPlanningsStateCodes';
import {getDefaultRentPeriod} from "../../../../../utils/timeUtils/timeUtils";
import moment from "moment/moment";

export type WorkPlanningsCreateModalWrapperProps = {
    rootPath: EntityGridRootPath;
    urlSearchParams: URLSearchParams;
    projectId: ProjectInfoRead['id'];
    projectType?: ProjectInfoRead['projectType'];
    projectStartDate?: Date | undefined;
    projectEndDate?: Date | undefined;
} & Partial<ModalFormNEWProps>;

type CreateModalWrapper = FunctionComponent<WorkPlanningsCreateModalWrapperProps>;

export const WorkPlanningsCreateModalWrapper: CreateModalWrapper = memo((props) => {
    const {
        rootPath,
        urlSearchParams,
        projectStartDate,
        projectEndDate,
        projectId,
        projectType,
        initialValues: _initialValues,
        ...modalProps
    } = props;
    const businessAccountId = useAppSelector(businessAccountIdSelector);

    const defaultDates = getDefaultRentPeriod(moment(getCurrentServerTime()));

    const initialValues: Partial<WorkPlanningCreateModalFormData> = {
        ..._initialValues,
        projectId,
        projectType,
        projectStartDate: projectStartDate || defaultDates.startDate.toDate(),
        projectEndDate: projectEndDate || defaultDates.endDate.toDate(),
        state: getDefaultStateForCreateModal(projectType, _initialValues?.projectStateCode),
    };

    return (
        <WorkPlanningsCreateModal
            {...(modalProps ?? {})}
            initialValues={initialValues}
            businessAccountId={businessAccountId}
            title={'Создание новых работ'}
            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
            rootPath={rootPath}
            urlSearchParams={urlSearchParams}
        />
    );
});
