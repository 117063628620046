/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE: This file is auto generated. Do not edit this file manually.
 */

export const locale = {
    'aspect.global.cancel': `Cancel`,
    'aspect.global.add': `Add`,
    'aspect.global.create': `Create`,
    'aspect.global.send': `Send`,
    'aspect.global.save': `Save`,
    'aspect.global.ok': `OK`,
    'aspect.global.yes': `Yes`,
    'aspect.global.no': `No`,
    'aspect.global.lastActivity': `Last activity`,
    'aspect.global.lastOperation': `Last operation`,
    'aspect.global.email': `Email`,
    'aspect.global.phoneNumber': `Phone number`,
    'aspect.global.lastUpdateAuthor': `Last change author`,
    'aspect.global.lastname': `Last name`,
    'aspect.global.firstname': `First name`,
    'aspect.global.secondname': `Second name`,
    'aspect.global.stats': `Stats`,
    'aspect.global.totalNumber': `Total`,
    'aspect.global.inTotal': `In total`,
    'aspect.global.any': `Any`,
    'aspect.global.more': `More`,
    'aspect.global.error': `Error`,
    'aspect.global.subsetTermOf': `of`,
    'aspect.global.sex.female': `Female`,
    'aspect.global.sex.male': `Male`,
    'aspect.global.example': `Example`,
    'aspect.global.personFullName': `Full name`,
    'aspect.global.turnedOn.plural': `Turned on`,
    'aspect.global.turnedOff.plural': `Turned off`,
    'aspect.global.fromAsStartOfTheNumbersInterval': `from`,
    'aspect.global.fromAsEndOfTheNumbersInterval': `to`,
    'aspect.global.form.notificationScheme.default': `Default`,
    'aspect.global.rentIndicators': `Rent indicators`,
    'aspect.global.occupied': `Occupied`,
    'aspect.global.inventory': `Inventory`,
    'aspect.global.condition': `Condition`,
    'aspect.global.empty': `Empty`,
    'aspect.global.nomenclature': `Nomenclature`,
    'aspect.global.extraFeatures': `Extra features`,
    'aspect.global.indicators': `Indicators`,
    'aspect.global.archive': `Archive`,
    'aspect.global.file': `{value, plural, one {file} other {files}}`,
    'aspect.global.copyLink': `Copy the link`,
    'aspect.global.popupNotifications.linkCopied': `Link copied`,
    'aspect.mainMenu.dashboard': `Dashboard`, // Пункт меню Dashboard и заголовок страницы
    'aspect.mainMenu.calendar': `Calendar`, // Пункт меню Календарь и заголовок страницы
    'aspect.mainMenu.projects': `Projects`, // Пункт меню Проекты и заголовок страницы
    'aspect.mainMenu.counterparties': `Counterparties`, // Пункт меню Контрагенты и заголовок страницы
    'aspect.mainMenu.products': `Products`, // Пункт меню Продукты и заголовок страницы
    'aspect.mainMenu.createOperation': `New operation`,
    'aspect.mainMenu.operationForm': `Operation form`, // Для шапки, при создании операции
    'aspect.mainMenu.settings': `Settings`,
    'aspect.mainMenu.support': `Support`,
    'aspect.mainMenu.support.requests': `Support requests`,
    'aspect.mainMenu.kb': `Knowledge base`,
    'aspect.mainMenu.notifications': `Notifications`,
    'aspect.mainMenu.categories': `Categories`,
    'aspect.mainMenu.operations': `Operations`,
    'aspect.mainMenu.profile': `Profile`,
    'aspect.mainMenu.inventory': `Inventory`,
    'aspect.mainMenu.crew': `Crew`,
    'aspect.mainMenu.kits': `Kits`,
    'aspect.mainMenu.analytics': `Статистика`,
    'aspect.mainMenu.subrent': `Subrent`,
    'aspect.mainMenu.subrent.rent': `Subrent`,
    'aspect.mainMenu.rent': `Аренда`,
    'aspect.currentUserProfilePopup.signOut': `Sign out`,
    'aspect.currentUserProfilePopup.changeBusinessAccount': `Change company`,
    'aspect.currentUserProfilePopup.addBusinessAccount': `Add company`,
    'aspect.currentUserProfilePopup.businessAccountsOf': `Сompanys of`,
    'aspect.entityType.product': `Product`,
    'aspect.entityType.renter': `Renter`,
    'aspect.entityType.subrent': `Subrent`,
    'aspect.entityType.project': `Project`,
    'aspect.entityType.operation': `Operation`,
    'aspect.entityType.operations': `Operations`,
    'aspect.entityType.element': `Planning`, // в тех доках element или rentElement, в переводе planning
    'aspect.entityType.elements': `Plannings`,
    'aspect.entityType.businessAccount': `Company`, // в тех доках business account, в переводе просто account
    'aspect.entityType.variant': `Variant`,
    'aspect.entityType.instance': `Instance`, // (пока может и не используется)
    'aspect.entityType.instances': `Instances`,
    'aspect.entityType.kit': `Kit`,
    'aspect.entityType.template': `Template`,
    'aspect.entityType.templates': `Templates`,
    'aspect.entityType.counterparty': `Counterparty`,
    'aspect.entityType.counterparties': `Counterparties`,
    'aspect.entityType.documentTemplate': `Document template`,
    'aspect.entityType.commonDocumentTemplate': `Common document template`,
    'aspect.entityType.reportConfiguration': `Report configuration`,
    'aspect.entityType.reportVisualization': `Report visualization`,
    'aspect.entityType.reportInstance': `Report instance`,
    'aspect.entityType.payment': `Платёж`,
    'aspect.entityType.user': `User`,
    'aspect.entityType.systemOption': `System option`,
    'aspect.entityType.category': `Category`,
    'aspect.entityType.customField': `Custom field`,
    'aspect.entityType.contact': `Contact`,
    'aspect.entityType.location': `Location`,
    'aspect.entityType.vehicle': `Vehicle`,
    'aspect.entityType.inventoryMovement': `Inventory movement`,
    'aspect.entityType.pricingScheme': `Pricing scheme`,
    'aspect.entityType.transportation': `Transportation`,
    'aspect.entityType.expense': `Expense`,
    'aspect.entityType.workPlannings': `Work plannings`,
    'aspect.entityType.attachment': `Attachment`,
    'aspect.entityType.profession': `Profession`,
    'aspect.entityType.crewMember': `Crew member`,
    'aspect.time.year': `{value, plural, one {Year} other {Years}}`,
    'aspect.time.month': `{value, plural, one {Month} other {Months}}`,
    'aspect.time.week': `{value, plural, one {Week} other {Weeks}}`,
    'aspect.time.day': `{value, plural, one {Day} other {Days}}`,
    'aspect.time.hour': `{value, plural, one {Hour} other {Hours}}`,
    'aspect.time.minute': `{value, plural, one {Minute} other {Minutes}}`,
    'aspect.time.today': `Today`,
    'aspect.time.yesterday': `Yesterday`,
    'aspect.time.tomorrow': `Tomorrow`,
    'page.security.roles': `Roles`,
    'page.security.roles.agent': `Agent`,
    'page.security.roles.admin': `Administrator`,
    'aspect.rentBusiness.warehouse': `Warehouse`,
    'aspect.rentBusiness.underMaintenance': `On serving`,
    'aspect.rentBusiness.rentPeriod': `Rent period`,
    'aspect.footer.about': `About`,
    'aspect.footer.privacyPolicy': `Privacy policy`,
    'aspect.grid.rowsPerPageDashboard': `{total, plural, =0 {Nothing found} =1 {<span style="font-weight: 500">1</span> of <span style="font-weight: 500">1</span>} other {<span style="font-weight: 500">{from}</span>-<span style="font-weight: 500">{to}</span> of #}}`, // В РАЗДЕЛ ДЕШБОРД ПЕРЕНЕСТИ НУЖНО
    'aspect.grid.rowsPerPage': `{total, plural, =0 {Nothing found} =1 {Showing <span style="font-weight: 500">1</span> of <span style="font-weight: 500">1</span>} other {Showing <span style="font-weight: 500">{from}</span>-<span style="font-weight: 500">{to}</span> of #}}`,
    'aspect.grid.rowsPerPageWithoutOther': `{total, plural, =0 {Nothing found} =1 {<span style="font-weight: 500">1</span> of <span style="font-weight: 500">1</span>} other {<span style="font-weight: 500">{from}</span>-<span style="font-weight: 500">{to}</span> of #}}`,
    'aspect.grid.empty': `Nothing found`,
    'aspect.grid.recordsInRecordsCount': `records`,
    'aspect.grid.clearSelected': `Clear`,
    'aspect.grid.selectedCount': `Selected`,
    'aspect.grid.toSearchInArchiveSwitchTheMode': `To search the archive, switch the mode`,
    'aspect.grid.showPerPageCountPreLabel': `Show per page`,
    'aspect.grid.tooltip.022': `Records numbers on the page (x-y), and total amount of the records found after all the filters was applied`,
    'aspect.grid.column.time': `Time`,
    'aspect.grid.column.quantity': `Quantity`,
    'aspect.grid.column.name': `Name`,
    'aspect.grid.column.status': `Status`,
    'aspect.grid.column.discount': `Discount`,
    'aspect.grid.column.booked': `Booked`,
    'aspect.grid.column.rent': `Rent`,
    'aspect.grid.column.responsible': `Assignee`,
    'aspect.grid.column.activity': `Activity`,
    'aspect.grid.column.renter': `Renter`,
    'aspect.grid.column.begin': `Start`,
    'aspect.grid.column.shortage': `Shortage`,
    'aspect.grid.column.delay': `Delay`,
    'aspect.grid.column.externalCode': `External code`,
    'aspect.grid.column.maintenance': `Maintenance`,
    'aspect.grid.column.pricePerShift': `Price per shift`,
    'aspect.grid.column.shiftCount': `Shift count`, // На русском было переименовано на: Смены к оплате
    'aspect.grid.column.project': `Project`,
    'aspect.grid.column.sum': `Sum`,
    'aspect.grid.column.finalTotalPrice': `Total sum`,
    'aspect.grid.column.author': `Author`,
    'aspect.grid.column.product': `Product`,
    'aspect.grid.column.variant': `Variant`,
    'aspect.grid.column.endDate': `End date`,
    'aspect.grid.column.stateCodeBefore': `Previous state`,
    'aspect.grid.column.priceInstancePerShift': `Price ins./sh.`,
    'aspect.grid.column.actions': `Actions`,
    'aspect.grid.column.costInstancePerShift': `Cost ins./sh.`,
    'aspect.grid.column.number': `Number`,
    'aspect.grid.column.image': `Image`,
    'aspect.grid.column.totalCost': `Cost`,
    'aspect.grid.column.debt': `Debt`,
    'aspect.grid.column.dayOfWeek': `Day of week`,
    'aspect.grid.tooltip.toNextPage': `Next page`,
    'aspect.grid.tooltip.toTheFirstPage': `To the first page`,
    'aspect.grid.tooltip.toPreviousPage': `Previous page`,
    'aspect.grid.tooltip.toTheLastPage': `To the last page`,
    'aspect.excelExport.selectOption.allColumns': `All columns`,
    'aspect.excelExport.selectOption.allPages': `All pages`,
    'aspect.excelExport.selectOption.includeComponents': `Include kit components`,
    'aspect.excelExport.popupLabel': `Export to Excel`,
    'aspect.excelExport.popupAction': `Export`,
    'aspect.excelExport.excelFileNameProjectElements': `Project plannings`,
    'aspect.excelExport.excelFileNameOperationElements': `Operation plannings`,
    'aspect.excelExport.defaultExcelExportFileName': `Plannings from calendar`,
    'aspect.excelExport.exportToExcelFailed': `Export to Excel failed`,
    'aspect.grid.popup.title.actions': `Actions`,
    'aspect.grid.popup.title.amount': `Amount`,
    'aspect.modal.abortEdit': `Abort the editing?`,
    'aspect.modal.abortCreation': `Abort the creation?`,
    'aspect.plural.project': `{value, plural, one {project} other {projects}}`,
    'aspect.plural.inProject': `{value, plural, one {project} other {projects}}`,
    'aspect.plural.forWork': `{value, plural, =0 {work} one {works} few {works} other {works}}`,
    'aspect.plural.element': `{value, plural, one {planning} other {plannings}}`,
    'aspect.plural.activeNeuterGender': `active`,
    'aspect.plural.activeMaleGender': `active`,
    'aspect.plural.forActive': `active`,
    'aspect.plural.forElement': `{value, plural, one {planning} other {plannings}}`,
    'aspect.plural.operation': `{value, plural, one {operation} other {operations}}`,
    'aspect.plural.instance': `{value, plural, one {instance} other {instances}}`,
    'aspect.plural.instanceAdded': `{value, plural, one {instance added} other {instances added}}`,
    'aspect.plural.nomenclatureAdded': `{value, plural, one {nomenclature added} other {nomenclatures added}}`,
    'aspect.plural.shift': `{value, plural, one {shift} other {shifts}}`, // В форме операции в попапе с проблемами
    'aspect.plural.fromShifts': `{value, plural, one {shift} other {shifts}}`, // Для ценовых смен
    'aspect.plural.anonymousInstance': `{value, plural, one {anonymous instance} other {anonymous instances}}`, // В форме операции в попапе с проблемами
    'aspect.plural.anonymous': `{value, plural, one {anonymous} other {anonymous}}`, // В форме операции в попапе создания/редактирования элемента
    'aspect.plural.filter': `{value, plural, one {filter} other {filters}}`, // В фильтрах кол-во выбранных фильтров
    'aspect.plural.field': `{value, plural, one {field} other {fields}}`,
    'aspect.plural.subcategory': `{value, plural, one {subcategory} other {subcategories}}`,
    'aspect.plural.counterparty': `{value, plural, one {counterparty} other {counterparties}}`,
    'aspect.plural.attachment': `{value, plural, one {attachment} other {attachments}}`,
    'aspect.plural.contact': `{value, plural, one {contact} other {contacts}}`,
    'aspect.plural.crewMember': `{value, plural, one {crew member} other {crew members}}`,
    'aspect.plural.deleteProfession': `This action will also remove her ties to <b>{value}</b> {value, plural, =0 {crew members} one {crew member} other {crew members}} and erase the rates set for them in the this specialty.`,
    'aspect.plural.fileWillNotBeUploadedMessage': `{value, plural, =0 {файлов не будет загружено} one {файл не будет загружен} few {файла не будет загружено} other {файлов не будет загружено}}`,
    'aspect.plural.scanError': `{value, plural, =0 {Ошибок} one {Ошибка} few {Ошибки} other {Ошибок}} сканирования`,
    'aspect.plural.product': `{value, plural, one {product} other {products}}`,
    'aspect.plural.kit': `{value, plural, one {kit} other {kits}}`,
    'aspect.dataPresence.notSpecified.m': `Not specified`,
    'aspect.dataPresence.notSpecified.f': `Not specified`,
    'aspect.dataPresence.notSpecified.n': `Not specified`,
    'aspect.dataPresence.notSpecified.many': `Not specified`,
    'aspect.dataPresence.dataLoading': `Loading...`, // В выпадающем списке сущностей, при загрузке
    'aspect.dataPresence.dataNotFound': `Nothing found`, // В выпадающем списке сущностей, когда ничего не найдено
    'aspect.dataPresence.dataLoadingError': `Loading error`, // В выпадающем списке сущностей, когда ошибка загрузки данных
    'aspect.dataPresence.absent': `Absent`,
    'aspect.dataPresence.noImage': `No image`,
    'aspect.dataPresence.notAssigned': `Not assigned`,
    'aspect.dataPresence.notSet': `Not set`,
    'aspect.dataPresence.fieldIsRequired': `Required field`,
    'aspect.dataPresence.neverOccured': `Never occured`,
    'aspect.dataPresence.noProductCategoriesExist': `No product categories exist`,
    'aspect.dataPresence.noRenterCategoriesExist': `No counterparty categories exist`,
    'aspect.dataPresence.notCharged': `Not charged`,
    'aspect.dataPresence.popupNotification.failedToLoadData': `Failed to load data`,
    'aspect.counter.currentDelayChildEntitiesCount': `Delays`,
    'aspect.counter.currentDelaySum': `Delays sum`,
    'aspect.counter.currentShortageChildEntitiesCount': `Shortages`,
    'aspect.counter.currentShortageSum': `Shortages sum`,
    'aspect.counter.currentBookedSum': `Booked`,
    'aspect.counter.currentRentedSum': `Rented`,
    'aspect.counter.totalReturnedSum': `Returned`,
    'aspect.counter.lastActivityDate': `Last activity`,
    'aspect.counter.elementCount': `Plannings`,
    'aspect.counter.lastReturnDate': `Last return date`,
    'aspect.problem.delays': `Delays`,
    'aspect.problem.shortages': `Shortages`,
    'aspect.problem.delay': `Delay`,
    'aspect.problem.shortage': `Shortage`,
    'aspect.problem.tooltip.bookShortage': `Book shortage`,
    'aspect.problem.tooltip.subrentShipmentDelay': `Subrent shipping delay`,
    'aspect.problem.tooltip.returnDelay': `Return delay`,
    'aspect.problem.tooltip.giveawayDelay': `Giveaway delay`,
    'aspect.problem.tooltip.subrentReturnToShipperDelay': `Subrent return to supplier delay`,
    'aspect.problem.tooltip.orderShortage': `Order shortage`,
    'aspect.action.activate': `Activate`,
    'aspect.action.block': `Block`,
    'aspect.action.unlock': `Unlock`,
    'aspect.action.toArchive': `Archive`,
    'aspect.action.fromArchive': `Unarchive`,
    'aspect.action.create': `Create`,
    'aspect.action.createRenter': `Create counterparty`,
    'aspect.action.createProject': `Create project`,
    'aspect.action.edit': `Edit`,
    'aspect.action.comment': `Comment`,
    'aspect.action.delete': `Delete`,
    'aspect.action.duplicate': `Duplicate`,
    'aspect.action.cancel': `Cancel`,
    'aspect.instanceTransitions.sendUnderMaintenance': `To maintenance`,
    'aspect.instanceTransitions.decomission': `Decomission`,
    'aspect.expenseTransitions.cancel': `Cancel`,
    'aspect.expenseTransitions.takeIntoAccount': `Учесть`,
    'aspect.rentOperation.action.PROLONG': `Prolong`,
    'aspect.rentOperation.action.RETURN': `Return`,
    'aspect.rentOperation.action.RETURN_BROKEN': `Return broken`,
    'aspect.rentOperation.action.LOST_NO_RETURN': `Mark as lost`,
    'aspect.rentOperation.action.CORRECT': `Correct`,
    'aspect.rentOperation.action.GIVE_AWAY': `Give away`,
    'aspect.rentOperation.action.CANCEL': `Cancel`,
    'aspect.rentOperation.action.BOOK': `Book`,
    'aspect.rentOperation.action.ORDER': `Order`,
    'aspect.rentOperation.action.DRAFT': `Draft`,
    'aspect.rentOperation.action.SUBRENT_DRAFT': `Draft subrent`,
    'aspect.rentOperation.action.SUBRENT_BOOK_SHIPMENT': `Book subrent`,
    'aspect.rentOperation.action.SUBRENT_ACCEPT_SHIPMENT': `Accept shipment`,
    'aspect.rentOperation.action.SUBRENT_RETURN_TO_SHIPPER': `Return to supplier`,
    'aspect.rentOperation.action.SUBRENT_CANCEL': `Cancel subrent`,
    'aspect.rentOperation.action.SUBRENT_PROLONG': `Prolong subrent`,
    'aspect.rentOperation.type.ORDER': `Ordering`,
    'aspect.rentOperation.type.BOOK': `Booking`,
    'aspect.rentOperation.type.GIVE_AWAY': `GIve away`,
    'aspect.rentOperation.type.PROLONG': `Prolongation`,
    'aspect.rentOperation.type.RETURN': `Return`,
    'aspect.rentOperation.type.RETURN_BROKEN': `Return broken`,
    'aspect.rentOperation.type.LOST_NO_RETURN': `Lost during rent`,
    'aspect.rentOperation.type.CANCEL': `Canceling`,
    'aspect.rentOperation.type.CORRECT': `Correct`,
    'aspect.rentOperation.type.DRAFT': `Drafting`,
    'aspect.rentOperation.type.AUTOMATIC_RULE': `Auto rule`,
    'aspect.rentOperation.type.SUBRENT_DRAFT': `Subrent drafting`,
    'aspect.rentOperation.type.SUBRENT_BOOK_SHIPMENT': `Subrent booking`,
    'aspect.rentOperation.type.SUBRENT_ACCEPT_SHIPMENT': `Acceptance of shipment`,
    'aspect.rentOperation.type.SUBRENT_RETURN_TO_SHIPPER': `Return to supplier`,
    'aspect.rentOperation.type.SUBRENT_CANCEL': `Subrent canceling`,
    'aspect.rentOperation.type.SUBRENT_PROLONG': `Subrent prolongation`,
    'aspect.currencies.ru': `Russian Ruble`,
    'aspect.currencies.us': `United States Dollar`,
    'aspect.currencies.eu': `Euro`,
    'aspect.currencies.AMD': `Armenian Dram`,
    'aspect.currencies.GEL': `Georgian Lari`,
    'aspect.currencies.BYR': `Belarusian Ruble`,
    'aspect.currencies.KZT': `Kazakhstani tenge`,
    'aspect.currencies.KGS': `Kyrgyz som`,
    'aspect.currencies.UZS': `Uzbekistani sum`,
    'aspect.currencies.AZN': `Azerbaijani manat`,
    'aspect.currencies.THB': `Thai baht`,
    'aspect.currencies.IDR': `Indonesian rupiah`,
    'aspect.currencies.AED': `UAE Dirham`,
    'aspect.currencies.TJS': `Tajikistani Somoni`,
    'aspect.filters.placeholder.notSelected.neutralGender': `Not selected`,
    'aspect.filters.placeholder.notSelected.maleGender': `Not selected`,
    'aspect.filters.placeholder.notSelected.femaleGender': `Not selected`,
    'aspect.filters.placeholder.any.famaleGender': `Any`,
    'aspect.filters.placeholder.any.maleGender': `Any`,
    'aspect.filters.advancedFilters.showButton': `Show advanced filters`,
    'aspect.filters.advancedFilters.hideButton': `Hide advanced filters`,
    'aspect.filters.searchFieldPlaceholder': `Search`,
    'aspect.filters.type': `Type`,
    'aspect.filters.status': `State`,
    'aspect.filters.renter': `Renter`,
    'aspect.filters.project': `Project`,
    'aspect.filters.hideInactive': `Hide inactive`,
    'aspect.filters.hideArchive': `Hide archive`,
    'aspect.filters.problem': `Problem`,
    'aspect.filters.problem.value.withoutProblem': `No problem`,
    'aspect.filters.problem.value.withDelay': `Delay`,
    'aspect.filters.problem.value.withShortage': `Shortage`,
    'aspect.filters.problem.value.withInstanceShortage': `Instance shortage`,
    'aspect.filters.problem.value.withAnyProblem': `Any problem`,
    'aspect.filters.startDateFrom': `Start date from`,
    'aspect.filters.endDateFrom': `End date from`,
    'aspect.filters.upperDateLimitLabel': `to`,
    'aspect.filters.creationDateTitlePart': `Creation date from`,
    'aspect.filters.product': `Product`,
    'aspect.filters.product.placeholder': `Any`,
    'aspect.filters.sortBy': `Sort by`,
    'aspect.filters.sortByRenter': `Sort by renter`,
    'aspect.filters.sortByProject': `Sort by project`,
    'aspect.filters.sortByProduct': `Sort by product`,
    'aspect.filters.sortByState': `Sort by state`,
    'aspect.filters.lastActivityDate': `Last activity from`,
    'aspect.filters.tooltip.a2': `On click shows and hides the block with advanced extra filters`,
    'aspect.categoryTreeFilter.createNewCategoriesLink': `Create new`,
    'aspect.filters.operations.authorField': `Author`,
    'aspect.filters.operations.authorField.placeholder': `Any`,
    'aspect.filters.products.pricePerShift': `Price per shift`,
    'aspect.forms.placeholder.selectEmployee': `Select an employee`,
    'aspect.forms.placeholder.selectCategory': `Select a category`,
    'aspect.forms.placeholder.selectRenter': `Select a renter`,
    'aspect.forms.imageFormatResctrictionsString': `Image in PNG or JPEG format`,
    'aspect.forms.imagesFormatResctrictionsString': `Images in PNG or JPEG format`,
    'aspect.forms.downloadFile': `Download file`,
    'aspect.forms.uploadFile': `Upload file`,
    'aspect.forms.uploadFiles': `Upload files`,
    'aspect.forms.problem.fileNotUploadedBeginning': `File`,
    'aspect.forms.problem.fileNotUploadedEnding': `upload failed`,
    'aspect.forms.placeholder.selectCountries': `Select countries`, // во множественное число перенес
    'aspect.forms.placeholder.selectCategories': `Select categories`, // во множественное число перенес
    'aspect.forms.mainImage': `Main image`,
    'aspect.forms.extraImages': `Extra images`,
    'aspect.forms.validation.fieldLengthMinimum': `Field length must be at least`,
    'aspect.forms.validation.fieldLengthMustBeExactly': `Field length must be`,
    'aspect.forms.validation.symbols': `symbols`,
    'aspect.forms.validation.fieldMustMatchThePattern': `Field must match the pattern`,
    'aspect.forms.validation.fieldLengthMaximum': `Field length must be at most`,
    'aspect.forms.validation.includingFormattingTags': `including text formatting hidden tags`,
    'aspect.stateCode.product.NEW': `New`,
    'aspect.stateCode.product.ACTIVE': `Active`,
    'aspect.stateCode.product.NOTAVAILABLE': `Not available`,
    'aspect.stateCode.renter.NEW': `New`,
    'aspect.stateCode.renter.ACTIVE': `Active`,
    'aspect.stateCode.renter.BLOCKED': `Blocked`,
    'aspect.stateCode.project.NEW': `New`,
    'aspect.stateCode.project.INPROGRESS': `In progress`,
    'aspect.stateCode.project.FINISHED': `Finished`,
    'aspect.stateCode.shipping.NEW': `New`,
    'aspect.stateCode.shipping.INPROGRESS': `In progress`,
    'aspect.stateCode.shipping.FINISHED': `Finished`,
    'aspect.stateCode.element.ORDERED': `Ordered`,
    'aspect.stateCode.element.BOOKED': `Booked`,
    'aspect.stateCode.element.RENT': `Rent`,
    'aspect.stateCode.element.RETURNED': `Returned`,
    'aspect.stateCode.element.RETURNED_BROKEN': `Returned broken`,
    'aspect.stateCode.element.LOST_DURING_RENT': `Lost during rent`,
    'aspect.stateCode.element.CANCELED': `Canceled`,
    'aspect.stateCode.element.OTHER': `Other`,
    'aspect.stateCode.element.DRAFT': `Draft`,
    'aspect.stateCode.element.SUBRENT_DRAFT': `Черновик СА`,
    'aspect.stateCode.element.SUBRENT_SHIPMENT_BOOKED': `Бронь СА`,
    'aspect.stateCode.element.SUBRENT': `Субаренда`,
    'aspect.stateCode.element.SUBRENT_RETURNED_TO_SHIPPER': `Завершено СА`,
    'aspect.stateCode.element.SUBRENT_CANCELED': `Отменено  СА`,
    'aspect.stateCode.user.NEW': `New`,
    'aspect.stateCode.user.INVITED': `Invited`,
    'aspect.stateCode.user.ACTIVE': `Active`,
    'aspect.stateCode.user.BLOCKED': `Blocked`,
    'aspect.stateCode.instance.notInStock': `Not in stock`,
    'aspect.stateCode.instance.active': `Active`,
    'aspect.stateCode.instance.undermaintenance': `Under maintenance`,
    'aspect.stateCode.instance.decommissioned': `Decomissioned`,
    'aspect.stateCode.instance.unaccounted': `Unaccounted`,
    'aspect.stateCode.instance.accounted': `Accounted`,
    'aspect.notificationMessage.typeCode.RENTACTIVITY': `Rent activity`,
    'aspect.notificationMessage.typeCode.TIMEOUTNOTICE': `Timeout`,
    'aspect.notificationMessage.typeCode.WARNING': `Warning`,
    'aspect.notificationMessage.typeCode.PROBLEM': `Problem`,
    'aspect.notificationMessage.typeCode.ASSIGNMENT': `Assignment`,
    'aspect.notificationMessage.typeCode.ASSIGNMENTTOWORK': `Назначение на работы`,
    'aspect.notificationMessage.typeCode.NEWPAYMENTFROMSTOREFRONT': `Новый платеж с витрины`,
    'aspect.popupNotifications.connectionError': `Connection error`,
    'aspect.popupNotifications.noInternetOrServerUnavailable': `No internet or server is unavailable`,
    'aspect.popupNotifications.internalServerError': `Internal server error`,
    'aspect.popupNotifications.validationError': `Validation error`,
    'aspect.popupNotifications.browserTabClosingChangesMayNotSave': `Changes may not be saved`,
    'aspect.popupNotifications.tryToRepeatYourRequestAgain': `Try to repeat your request`,
    'aspect.global.tooltip.shortNameFullNameDoesNotExists': `Short name (full name is empty)`,
    'aspect.customField.BOOLEAN': `Boolean`,
    'aspect.customField.LONG': `Long`,
    'aspect.customField.DOUBLE': `Double`,
    'aspect.customField.STRING': `String`,
    'aspect.customField.DATE': `Date`,
    'aspect.customField.multiple.BOOLEAN': `Boolean list`,
    'aspect.customField.multiple.LONG': `Long list`,
    'aspect.customField.multiple.DOUBLE': `Double list`,
    'aspect.customField.multiple.STRING': `String list`,
    'aspect.customField.multiple.DATE': `Date list`,
    'aspect.rentIndicators.instanceCount': `Кол-во экземпляров`,
    'aspect.rentIndicators.priceBeforeDiscount': `Сумма до скидки`,
    'aspect.rentIndicators.discount': `Скидка`,
    'aspect.rentIndicators.costBeforeTaxes': `Сумма до налога`,
    'aspect.rentIndicators.taxRate': `Налог`,
    'aspect.rentIndicators.finalTotalPrice': `Итого`,
    'aspect.fields.common.description': `Description`,
    'aspect.fields.common.comment': `Comment`,
    'aspect.fields.common.type': `Type`,
    'aspect.fields.common.unspecified': `Unspecified`,
    'aspect.fields.common.gender': `Gender`,
    'aspect.fields.common.shortTitle': `Short name`,
    'aspect.fields.common.fullName': `Full name`,
    'aspect.fields.common.code': `Code`,
    'aspect.fields.common.discount': `Discount`,
    'aspect.fields.common.assignee': `Responsible`,
    'aspect.fields.common.categories': `Categories`,
    'aspect.fields.common.image': `Image`,
    'aspect.fields.common.creationDate': `Creation date`,
    'aspect.fields.common.authorCreation': `Creation author`,
    'aspect.fields.common.lastUpdateDate': `Last update date`,
    'aspect.fields.common.lastUpdateAuthor': `Last update author`,
    'aspect.fields.common.defaultDiscount': `Default discount`,
    'aspect.fields.common.contactPerson': `Contact person`,
    'aspect.fields.common.position': `Position`,
    'aspect.fields.common.mnemoKey': `Mnemo key`,
    'aspect.fields.common.currency': `Currency`,
    'aspect.fields.common.pricePerShift': `Price per shift`,
    'aspect.fields.legalAttributes.individualEntrepreneurShort': `IE`,
    'aspect.fields.legalAttributes.legalEntityShort': `Leg. Ent.`,
    'aspect.fields.legalAttributes.physicalEntityShort': `Phys. Ent.`,
    'aspect.fields.legalAttributes.inn': `INN`, // Тут и ИП и Физ и Юр лица
    'aspect.fields.legalAttributes.kpp': `KPP`,
    'aspect.fields.legalAttributes.ogrn': `OGRN`,
    'aspect.fields.legalAttributes.innip': `INNIP`,
    'aspect.fields.legalAttributes.ogrnip': `OGRNIP`,
    'aspect.fields.legalAttributes.physicalAddress': `Addres`,
    'aspect.fields.legalAttributes.birthDate': `Date of birth`,
    'aspect.fields.legalAttributes.legalAddress': `Legal address`,
    'aspect.fields.legalAttributes.naturalPerson': `Natural person`,
    'aspect.fields.legalAttributes.individualEntrepreneur': `Individual entrepreneur`,
    'aspect.fields.legalAttributes.legalEntity': `legal entity`,
    'aspect.fields.legalAttributes.passportNumber': `Passport number`,
    'aspect.fields.renter.projectsCost': `Projects cost`,
    'aspect.fields.project.startDate': `Start date`,
    'aspect.fields.project.targetFinishDate': `Target finish date`,
    'aspect.fields.project.realFinishDate': `Actual finish date`,
    'aspect.fields.project.startDateProject': `Start date`,
    'aspect.fields.product.countriesOfProduction': `Countries of production`,
    'aspect.fields.product.totalAmount': `Total amount`,
    'aspect.fields.product.companyOfProduction': `Company of production`,
    'aspect.fields.product.lastRentEndDate': `Last rent end date`,
    'aspect.fields.instance.codeOrName': `Name`,
    'aspect.fields.instance.inventoryState': `Inventory state`,
    'aspect.fields.instance.availability': `Availability`,
    'aspect.fields.instance.lastMainenance': `Last maintenance`,
    'aspect.fields.instance.inProgressNow': `In progress now`,
    'aspect.fields.instance.inventoryNumber': `Official inventory number`,
    'page.error.underDevelopment.shortMessage': `Page is under development`, // Текст на страницу, если она в разработке
    'page.error.forbidden.shortMessage': `Access denied`, // Текст
    'page.error.forbidden.fullMessage': `You do not have sufficient permissions to view this page.`,
    'page.error.notFound.shortMessage': `Page not found`, // Текст на страницу, если не найдена
    'page.dashboard.tabs.status': `Status`,
    'page.dashboard.tabs.analytics': `Analytics`,
    'page.dashboard.giveAways': `Extraditions`,
    'page.dashboard.returns': `Returns`,
    'page.dashboard.datePickerOptionTodayOrEarlier': `Today or earlier`,
    'page.dashboard.tooltip.widget.delayed': `Information about all delayed rent plannings (return from renter delays and givaway to renters delays)`,
    'page.dashboard.tooltip.widget.shortages': `Information about all rent plannings with shortages (book shortages and order shortages)`,
    'page.dashboard.tooltip.widget.booked': `Information about all plannings in "Booked" state`,
    'page.dashboard.tooltip.widget.ordered': `Information about all plannings in "Ordered" state`,
    'page.dashboard.tooltip.widget.rented': `Information about all plannings in "Rented" state`,
    'page.dashboard.tooltip.widget.underMaintenance': `Information about all currently under maintenance products`,
    'page.dashboard.tooltip.widget.006': `Filter by giveaway delay, counter on the button corresponds for the total amount of plannings in "Booked" state with this type of delay`,
    'page.dashboard.tooltip.widget.007': `Filter by book shortage, counter on the button corresponds for the total amount of plannings with this type of shortage`,
    'page.dashboard.tooltip.widget.020': `Date interval to filter plannings, giveway or return must be in this time period to get to the resulting set. Week and month are processed as 7 and 30 days after the current moment`,
    'page.calendar.emptyListMessage': `Refine your search and try again!`,
    'page.calendar.emptyListMessageShowInactive': `To include inactive plannings switch the mode`,
    'page.calendar.rowsPerPage': `Отображаются`,
    'page.calendar.anyTime': `Any time`,
    'page.calendar.tabLabelMixedStates': `Mixed (kits only)`,
    'page.calendar.tabLabelDrafts': `Drafts`,
    'page.calendar.popupLabelRenderType': `Render type:`,
    'page.calendar.renderTypeOptionNormal': `Normal`,
    'page.calendar.renderTypeOptionCompact': `Compact`,
    'page.calendar.showAllElementsButton': `Show all plannings`,
    'page.calendar.groupingOption.rentPeriodStart': `Rent start date`,
    'page.calendar.groupingOption.rentPeriodEnd': `Rent end date`,
    'page.calendar.tooltip.a0': `Selector of the displayed time interval`,
    'page.calendar.tooltip.a3': `Filter by active planning states: "Ordered", "Booked" and "Rented"`,
    'page.calendar.tooltip.a4': `Filter by problem or waring on planning`,
    'page.calendar.tooltip.a5': `Filter by planning state`,
    'page.calendar.tooltip.a6': `Text search by product, kit or variant name and external code`,
    'page.calendar.tooltip.a7': `Filter by conterparty`,
    'page.calendar.tooltip.a8': `Filter by project`,
    'page.calendar.tooltip.a9': `Filter by shipping`,
    'page.calendar.tooltip.a10': `Filter by operation`,
    'page.calendar.tooltip.a11': `Filter by kit`,
    'page.calendar.tooltip.a12': `Filter by product`,
    'page.calendar.tooltip.a13': `Filter by variant`,
    'page.calendar.tooltip.a14': `Filter by instance`,
    'page.shippings.fieldValueBySupplier': `By supplier`,
    'page.shippings.modal.questions.sendToArchive': `Send the shipment to archive?`,
    'page.shippings.modal.questions.finish': `Finish the shipment?`,
    'page.shippings.modal.questions.resume': `Resume the shipment?`,
    'page.shippings.modal.questions.start': `Start the shipment?`,
    'page.shippings.modal.payment.paymentSum': `Payment sum`,
    'page.shippings.modal.payment.debtAfterSum': `Debt after payment`,
    'page.shippings.modal.payment.label': `Payment for shipment`,
    'page.shippings.actions.start': `Start`,
    'page.shippings.actions.finish': `Finish`,
    'page.shippings.actions.resume': `Resume`,
    'page.shippings.popupNotifications.created': `The shipment is created`,
    'page.shippings.popupNotifications.shipmentUpdated': `The shipment is changed`,
    'page.shippings.popupNotifications.shipmentsUpdated': `The shipments are changed`,
    'page.shippings.popupNotifications.archived': `The shipment has been archived`,
    'page.shippings.popupNotifications.unarchived': `The shipment was returned from the archive`,
    'page.shippings.popupNotifications.getListFailed': `Failed to load the list of shipments`,
    'page.shippings.popupNotifications.shipmentStateUpdated': `Shipment state is changed`,
    'page.shippings.popupNotifications.shipmentsStateUpdated': `Shipments states are changed`,
    'page.shippings.popupNotifications.updateFailed': `Failed to update the shipment`,
    'page.shippings.popupNotifications.paymentAccepted': `Payment for shipment processed`,
    'page.shippings.list.column.targetFinishDate': `Target finish date`,
    'page.shippings.list.column.realFinishDate': `Real finish date`,
    'page.shippings.card.field.cost': `Сost`,
    'page.shippings.card.paymentsAcceptedSum': `Accepted payment`,
    'page.shippings.card.debtSum': `Debt to the supplier`,
    'page.shippings.form.startDateMustBeEarlier': `The start date must not be after the end date`, // заменить на общее сообщение
    'page.shippings.form.endDateMustBeLater': `The end date must not be earlier than the start date`, // заменить на общее сообщение
    'page.shippings.form.formTitle.create': `Shipment creation`,
    'page.shippings.form.formTitle.edit': `Shipment editing`,
    'page.shippings.form.field.template': `Template`,
    'page.projects.superPageTitle': `Projects`,
    'page.projects.subsectionNameProject': `Projects`,
    'page.projects.fieldValueByRenter': `By renter`,
    'page.projects.modal.questions.sendToArchive': `To send the project to the archive?`,
    'page.projects.modal.questions.finish': `Finish the project?`,
    'page.projects.modal.questions.resume': `Resume the project?`,
    'page.projects.modal.questions.start': `Start the project?`,
    'page.projects.modal.payment.paymentSum': `Payment sum`,
    'page.projects.modal.payment.debtAfterSum': `Debt after payment`,
    'page.projects.modal.payment.label': `Acceptance of payment`,
    'page.projects.actions.acceptPayment': `Accept payment`,
    'page.projects.actions.start': `Start`,
    'page.projects.actions.finish': `Finish`,
    'page.projects.actions.resume': `Resume`,
    'page.projects.popupNotifications.created': `The project is created`,
    'page.projects.popupNotifications.projectUpdated': `The project is changed`,
    'page.projects.popupNotifications.projectsUpdated': `The projects is changed`,
    'page.projects.popupNotifications.archived': `The project has been archived`,
    'page.projects.popupNotifications.unarchived': `The project was returned from the archive`,
    'page.projects.popupNotifications.getListFailed': `Failed to load the list of projects`,
    'page.projects.popupNotifications.projectStateUpdated': `Project status changed`,
    'page.projects.popupNotifications.projectsStateUpdated': `Projects status changed`,
    'page.projects.popupNotifications.updateFailed': `Failed to update project`,
    'page.projects.popupNotifications.paymentAccepted': `Payment accepted`,
    'page.projects.superPageTitle.column.targetFinishDate': `Target finish date`,
    'page.projects.superPageTitle.column.realFinishDate': `Real finish date`,
    'page.projects.card.field.targetFinishDate.longTitle': `Expected completion date of the project`,
    'page.projects.card.field.draftCostBeforeTaxes': `Draft cost before taxes`,
    'page.projects.card.field.costBeforeTaxes': `Cost before taxes`,
    'page.projects.card.field.draftcostAfterTaxes': `Draft cost after taxes`,
    'page.projects.card.field.costAfterTaxes': `Cost after taxes`,
    'page.projects.card.paymentsAcceptedSum': `Accepted payment`,
    'page.projects.card.debtSum': `Debt`,
    'page.projects.card.taxesSum': `Taxes`,
    'page.projects.form.fullNameAlreadyUsed': `The project's full name has already been used`,
    'page.projects.form.shortNameAlreadyUsed': `The project's short name has already been used`,
    'page.projects.form.startDateMustBeEarlier': `The start date must not be after the end date`, // заменить на общее сообщение
    'page.projects.form.endDateMustBeLater': `The end date must not be earlier than the start date`, // заменить на общее сообщение
    'page.projects.form.formTitle.create': `Project creation`,
    'page.projects.form.formTitle.edit': `Project editing`,
    'page.projects.form.field.template': `Template`,
    'page.projects.form.field.taxPercent': `Tax (percentage)`,
    'page.projects.form.validation.shortNameUsedInRenter': `Short name is already used in current renter`,
    'page.projects.form.validation.fullNameUsedInRenter': `Full name is already used in current renter`,
    'page.templates.modal.create': `Create template`,
    'page.templates.modal.createProjectFromTemplate': `Project from template`,
    'page.templates.modal.questions.sendToArchive': `To send the template to the archive?`,
    'page.templates.popupNotifications.created': `The templates is created`,
    'page.templates.popupNotifications.templateUpdated': `The template is changed`,
    'page.templates.popupNotifications.templatesUpdated': `The templates is changed`,
    'page.templates.popupNotifications.archived': `The templates has been archived`,
    'page.templates.popupNotifications.unarchived': `The templates was returned from the archive`,
    'page.templates.popupNotifications.failedToLoadTemplateList': `Failed to load template list`,
    'page.templates.form.updateFormLabel': `Редактирование шаблона`,
    'page.templates.form.creationFormLabel': `Создание нового шаблона`,
    'page.templates.form.validation.fullNameAlreadyUsed': `Template full name already exists`,
    'page.templates.form.validation.shortNameAlreadyUsed': `Template short name already exists`,
    'page.products.customizeFieldsButton': `Customize fields`,
    'page.products.selectFieldButton': `Select field`,
    'page.products.groupName': `Group name`,
    'page.products.featureListIsEmpty': `No features exist`,
    'page.products.featureList': `Feature list`,
    'page.products.purchasePrice': `Purchase price`,
    'page.products.conditionDescription': `Condition description`,
    'page.products.customFieldsSetup': `Custom fields setup`,
    'page.products.groupCreationModal': `Group creation`,
    'page.products.createGroup': `Create group`,
    'page.products.attachedFiles': `Attached files`,
    'page.products.mainOnesLabel': `Main features`,
    'page.products.groupNameAlreadyExists': `Group name already exists`,
    'page.products.productFeatures': `Product features`,
    'page.products.instanceTrackingType.productBulk': `Bulk instances`,
    'page.products.instanceTrackingType.productTracked': `Tracked instances`,
    'page.products.instanceTrackingType.productVariantBulk': `Bulk instances of different variants`,
    'page.products.instanceTrackingType.productVariantTracked': `Tracked instances of different variants`,
    'page.products.actions.create': `Create product`,
    'page.products.actions.setAmount': `Set amount`,
    'page.products.popupNotifications.listPlaceholderNoFieldsExist': `No fields exist`,
    'page.products.popupNotifications.listPlaceholderAddCustomFields': `You should add custom fields to the product`,
    'page.products.popupNotifications.validation.groupNameAlreadyExists': `Group name already used`,
    'page.products.modal.questions.archive': `Archive the product?`,
    'page.products.modal.questions.activate': `Activate the product?`,
    'page.products.modal.questions.block': `Block the product?`,
    'page.products.modal.questions.unblock': `Unblock the product?`,
    'page.products.modal.questions.deleteEmptyGroups': `Empty groups will be deleted, proceed?`,
    'page.products.popupNotifications.created': `The product is created`,
    'page.products.popupNotifications.updated': `The product is changed`,
    'page.products.popupNotifications.archived': `The product has been archived`,
    'page.products.popupNotifications.unarchived': `The product was returned from the archive`,
    'page.products.popupNotifications.getListFailed': `Failed to load the list of products`,
    'page.products.popupNotifications.productStateUpdated': `Product status changed`,
    'page.products.popupNotifications.productsStateUpdated': `Products status changed`,
    'page.products.popupNotifications.customFieldsSetupUpdateFailed': `Failed to update fields setup`,
    'page.products.popupNotifications.customFieldsSetupUpdated': `Fields setup updated`,
    'page.products.tab.productList': `Products List`,
    'page.products.card.block.mainFeatures': `Main features`,
    'page.products.card.dimensions': `Dimensions (X, Y, Z)`,
    'page.products.card.block.Indicators': `Indicators`,
    'page.products.card.availableMaleCount': `Available`,
    'page.products.card.availableNeuterCount': `Available`,
    'page.products.card.notAvailableCount': `Out of rich`,
    'page.products.card.variants': `Variants`,
    'page.products.card.subrent': `Subrent`,
    'page.products.card.addFeatures': `Add features`,
    'page.products.card.showFeatures': `Show features`,
    'page.products.card.showAllFeatures': `Show all features`,
    'page.products.card.showAll': `Show all`,
    'page.products.form.validation.fullNameAlreadyUsed': `The product's full name has already been used`,
    'page.products.form.validation.shortNameAlreadyUsed': `The product's short name has already been used`,
    'page.products.form.validation.externalCodeAlreadyUsed': `The external code has already been used`,
    'page.products.form.field.totalAmount.longTitle': `Total amount of instances`,
    'page.products.form.field.underMaintenanceAmount.longTitle': `Amount of instances under maintenance`,
    'page.products.form.validation.underMaintenanceMoreThanTotal': `Amount of instances under maintenance must be less then total amount`,
    'page.products.form.formTitle.edit': `Product editing`,
    'page.products.form.formTitle.create': `Product creation`,
    'page.products.form.amountEditionFormLabel': `Amount of instances editing`,
    'page.products.form.field.companyOfProduction.longTitle': `Producer company`,
    'page.products.form.validation.priceTooHigh': `Price must be under 100 mln`,
    'page.products.form.block.extraFeautures': `Extra characteristics`,
    'page.products.form.includesVariations': `Includes variations`,
    'page.products.form.individualInstanceTracking': `Individual instance tracking`,
    'page.kits.tab.components': `Components`,
    'page.actions.createKit': `Create kit`,
    'page.actions.customizeComponents': `Customize components`,
    'page.actions.addComponent': `Add component`,
    'page.modal.questions.unblock': `Unblock the kit?`,
    'page.modal.questions.block': `Block the kit?`,
    'page.modal.questions.sendToArchive': `Send the kit to archive?`,
    'page.modal.questions.removeComponent': `Remove component from the kit?`,
    'page.kits.popupNotifications.stateChanged': `Kit state changed`,
    'page.kits.popupNotifications.failedToGetKitList': `Failed to load kit list`,
    'page.kits.popupNotifications.kitStatesChanged': `Kit states changed`,
    'page.kits.popupNotifications.kitCreated': `Kit created`,
    'page.kits.popupNotifications.kitSaveVersionConflict': `Kit update failed, someone changed it while you have been working with it`,
    'page.kits.popupNotifications.updateFailed': `Kit update failed`,
    'page.kits.popupNotifications.kitMembersUpdated': `Kit members list updated`,
    'page.kits.popupNotifications.updateVersionConflict': `Overwriting protection. Kit was changed by another user or in a different tab during editing`,
    'page.kits.popupNotifications.kitUpdated': `Kit updated`,
    'page.kits.popupNotifications.kitsUpdates': `Kits updated`,
    'page.kits.popupNotifications.kitArchived': `Kit archived`,
    'page.kits.popupNotifications.kitUnarchived': `Kit unarchived`,
    'page.kits.form.formTitle.edit': `Kit editing`,
    'page.kits.form.formTitle.create': `Kit creation`,
    'page.kits.form.validations.shortNameAlreadyUsed': `Kit short name already used`,
    'page.kits.form.validations.fullNameAlreadyUsed': `Kit full name already used`,
    'page.kits.form.hasMainMember': `Contains main member`,
    'page.kits.descriptionTab.numberOfComponents': `Number of components`,
    'page.kits.componentsTab.label.componentsCustomization': `Components customization`,
    'page.kits.componentsTab.label.componentsList': `Components list`,
    'page.kits.componentsTab.selectNomenclature': `Select nomenclature`,
    'page.kits.noComponentsExist': `No components exist`,
    'page.kits.needToAddComponents': `You should add components to the kit`,
    'page.kits.isMainMember': `Main member`,
    'page.kits.productState': `Product state`,
    'page.kits.priceWhenInKit': `Price in kit ins./sh.`,
    'page.kits.totalPriceWhenInKit': `Total price in kit per sh.`,
    'page.kits.order': `Order`,
    'page.renters.actions.activate': `Activate the counterparty`,
    'page.renters.actions.block': `Block the counterparty`,
    'page.renters.actions.unblock': `Unblock the counterparty`,
    'page.renters.modal.questions.sendToArchive': `Send the counterparty to the archive?`,
    'page.renters.modal.questions.activate': `Activate the counterparty?`,
    'page.renters.modal.questions.block': `Block the counterparty?`,
    'page.renters.modal.questions.resume': `Unblock the counterparty?`,
    'page.renters.popupNotifications.archived': `The counterparty is archived`,
    'page.renters.popupNotifications.unarchived': `The counterparty was returned from the archive`,
    'page.renters.popupNotifications.created': `The counterparty is created`,
    'page.renters.popupNotifications.getListFailed': `Failed to load the list of counterparties`,
    'page.renters.popupNotifications.renterUpdated': `Counterparty is updated`,
    'page.renters.popupNotifications.rentersUpdated': `Counterparties are updated`,
    'page.renters.popupNotifications.renterStateUpdated': `State of the renter is changed`,
    'page.renters.popupNotifications.rentersStateUpdated': `States of renters are changed`,
    'page.renters.popupNotifications.rentersCreateProjectImpossible': `You can't create a project in a blocked renter`,
    'page.renters.popupNotifications.failedToUpdateRenter': `Failed to update renter`,
    'page.renters.popupNotifications.failedToCreateRenter': `Failed to create renter`,
    'page.renters.list.tabs.counterparties': `Counterparties`,
    'page.renters.card.payedAmount': `Payed amount`,
    'page.renters.card.legalAddressResidence': `Legal address and address of residence`,
    'page.renters.card.fullNameAlreadyUsed': `Counterparty full name has already been used`,
    'page.renters.card.shortNameAlreadyUse': `Counterparty short name has already been used`,
    'page.renters.card.projectsBeforeTaxesCost': `Projects before taxes cost`,
    'page.renters.card.projectsTaxes': `Projects taxes`,
    'page.renters.card.projectAfterTaxesCost': `Projects after taxes cost`,
    'page.renters.form.onlyChapter': `The field must contain only characters from A to Z and from A to Z. the Allowed length of the field is from 1 to 4 characters`,
    'page.renters.form.mnemonicCodeAlreadyUsed': `Counterparty mnemonic code has already been used`,
    'page.renters.form.valiationMessageINNIP': `This field must contain only numbers. The allowed field length is 12 characters`,
    'page.renters.form.validationMessageOGRNIP': `This field must contain only numbers. The allowed field length is 15 characters`,
    'page.renters.form.valiationMessagePasport': `This field must contain only numbers. The allowed field length is 10 characters`,
    'page.renters.form.valiationMessageINN': `This field must contain only numbers. The allowed field length is 10 characters`,
    'page.renters.form.validationMessageKPP': `This field must match the format 0000ZZ000. The allowed field length is 9 characters`, // Я бы тут отдельно сразу для паспорта сделал, хоть они и одинаковые в рф
    'page.renters.form.validationMessageOGRN': `This field must contain only numbers. The allowed field length is 13 characters`,
    'page.renters.form.validationMessageEmail': `This field must contain a valid Email address`,
    'page.renters.form.formTitle.edit': `Counterparty editing`,
    'page.renters.form.formTitle.create': `Counterparty creation`,
    'page.renters.form.block.company': `Company`,
    'page.operations.totalDiscount': `Total discount`,
    'page.operations.modal.questions.sendToArchive': `Archive the operation?`,
    'page.operations.modal.questions.cancel': `Discard current operation?`,
    'page.operations.popupNotifications.archived': `Operation archived`,
    'page.operations.popupNotifications.unarchived': `Operation returned from archive`,
    'page.operations.popupNotifications.getListFailed': `Failed to load the list of operations`,
    'page.operations.popupNotifications.operationUpdated': `Operation updated`,
    'page.operations.popupNotifications.operationsUpdated': `Operations updated`,
    'page.operations.popupNotifications.commentUpdated': `Comment updated`,
    'page.operations.list.grid.column.creationDate': `Created on`,
    'page.operations.list.grid.column.activeElementsCount': `Active plannings`,
    'page.operations.list.grid.column.instanceCount': `Instances`,
    'page.operations.list.grid.column.activeInstancesCount': `Active instances`,
    'page.operations.list.tab.operationList': `Operations list`,
    'page.operations.list.hideAutomatic': `Скрыть автоматические`,
    'page.operations.popup.tooltip.001': `001`,
    'page.operations.popup.tooltip.003': `003`,
    'page.operations.popup.tooltip.005': `005`,
    'page.operations.popup.tooltip.006': `006`,
    'page.operations.popup.tooltip.008': `008`,
    'page.operations.popup.tooltip.009': `009`,
    'page.operations.popup.tooltip.010': `010`,
    'page.operations.popup.tooltip.011': `011`,
    'page.operations.popup.tooltip.012': `012`,
    'page.operations.popup.tooltip.013': `013`,
    'page.operations.popup.tooltip.014': `014`,
    'page.operations.popup.tooltip.016': `016`,
    'page.operations.popup.tooltip.017': `017`,
    'page.operations.popup.tooltip.018': `018`,
    'page.operations.popup.tooltip.goToProject': `goToProject`,
    'page.operations.popup.tooltip.goToProjectTemplate': `goToProjectTemplate`,
    'page.operations.popup.tooltip.goToRenter': `goToRenter`,
    'page.operations.popup.tooltip.goToSupplier': `goToSupplier`,
    'page.operations.popup.tooltip.goToCalendar': `goToCalendar`,
    'page.operations.popup.tooltip.edit': `edit`,
    'page.operations.popup.tooltip.close': `close`,
    'page.operations.popup.tooltip.goToShipping': `goToShipping`,
    'page.operations.card.tab.history': `History`,
    'page.operations.card.targetElementsState': `Target state`,
    'page.operations.card.activeElementsCount': `Active count`,
    'page.operations.card.elementsTotalAmount': `Total amount`,
    'page.operations.card.rentTermsOnCreation': `Rent terms on creation`,
    'page.operations.card.rentPeriodStartDate': `Rent start`,
    'page.operations.card.rentPeriodEndDate': `Rent end`,
    'page.operations.card.totalPriceBeforeDiscount': `Total cost before discount`,
    'page.operations.card.totalDiscount': `Total discount`,
    'page.operations.card.compositionOnCreation': `Composition on creation`,
    'page.operations.card.commonRentTermsOnCreation': `Common terms on creation`,
    'page.operations.card.shiftCount': `Shift count`, // На русском было переименовано на: Смены к оплате
    'page.operations.form.formTitle.comment': `Comment editing`,
    'page.newOperation.tab.warehouseSearch': `Warehouse search`,
    'page.newOperation.tab.currentComposition': `Current composition`,
    'page.newOperation.indicator.amountOfInstances': `Amount of units`,
    'page.newOperation.tooltip.disabledSubmitButtonOnEmptyComposition': `Can't submit the operation: composition is empty`,
    'page.newOperation.actions.discardOperation': `Discard operation`,
    'page.newOperation.actions.addToTheComposition': `Add to composition`,
    'page.newOperation.actions.removeFromOperation': `Remove from operation`,
    'page.modal.actions.ofXAvailableLabelEnd': `available`,
    'page.newOperation.modal.warningOnSubmit.modalTitle': `Warning`,
    'page.newOperation.modal.warningOnSubmit.operationCauseRequestShortage': `Operation will cause shortage in some requests`,
    'page.newOperation.modal.problemOnSubmit.modalTitle': `Problem`,
    'page.newOperation.modal.problemOnSubmit.notEnoughInstancesFor': `Недостаточно доступных экземпляров для`,
    'page.newOperation.modal.problemOnSubmit.notEnoughForProlongation': `prolongation`,
    'page.newOperation.modal.problemOnSubmit.notEnoughForGiveAway': `giveaway`,
    'page.newOperation.modal.problemOnSubmit.notEnoughForBooking': `booking`,
    'page.newOperation.modal.problemOnSubmit.button.submitOperation': `Submit operation`,
    'page.newOperation.popupNotifications.created': `Operation created`,
    'page.newOperation.popupNotifications.creationFailedDueShortage': `Not enough available product instances during the target rent period`,
    'page.newOperation.popupNotifications.creationFailed': `Failed to create operation`,
    'page.newOperation.editElementPopup.amountAvailable': `available`, // убрал '.. на складе'
    'page.newOperation.editElementPopup.instancesAmountInElement': `instances of the planning`,
    'page.newOperation.fastCreationForm.formTitle': `Operation creation`,
    'page.newOperation.fastCreationForm.pageTitle': `New operation`,
    'page.newOperation.tab.warehouse.grid.column.requestedCount': `Requested`,
    'page.newOperation.tab.warehouse.grid.column.addedCount': `Added`,
    'page.newOperation.tab.warehouse.filter.availableOnDateInterval': `Available during period`,
    'page.newOperation.tab.warehouse.filter.unitPrice': `Unit price`,
    'page.newOperation.tab.composition.defaultRentTerms': `Default terms`,
    'page.newOperation.tab.composition.grid.emptyCompositionTitle': `Operation is empty`,
    'page.newOperation.tab.composition.grid.emptyCompositionSubtitleWarehouseAvailable': `Add products on the tab "Warehouse search"`,
    'page.newOperation.tab.composition.grid.emptyCompositionSubtitleWarehouseUnavailable': `Composition has been cleared, create new operation`,
    'page.elements.popupNotifications.getListFailed': `Plannings list loading failed`,
    'page.elements.popupNotifications.elementsMovedToProject': `Plannings successfully moved to project`,
    'page.elements.popupNotifications.failedToMoveElementsToProject': `Moving plannings to project failed`,
    'page.elements.actions.moveToProject': `Move to project`,
    'page.elements.actions.move': `Move`,
    'page.elements.actions.retryAfterError': `Retry`,
    'page.elements.popupNotifications.placeholder.selectProject': `Select project`,
    'page.elements.popupCard.amount': `Amount`,
    'page.elements.popupCard.rentElementNumber': `Number`,
    'page.elements.tooltip.currentOperation': `Current operation is the latest for this planning (it is the actual operation)`,
    'page.categories.parentCategory': `Parent category`,
    'page.categories.categoryName': `Category name`,
    'page.categories.modal.questions.delete': `Are you sure you want to delete the category?`,
    'page.categories.modal.checkbox.deleteLinks': `Delete links with`,
    'page.categories.modal.checkbox.deleteSubcategories': `Delete subcategories`,
    'page.categories.modal.withCountRentersLabelEnding': `counterparties`,
    'page.categories.modal.withCountProductsLabelEnding': `products and kits`,
    'page.categories.popupNotifications.deletionFailed': `Category deletion failed`,
    'page.categories.popupNotifications.deleted': `Category deleted`,
    'page.categories.popupNotifications.updateFailed': `Category update failed`,
    'page.categories.popupNotifications.updated': `Category updated`,
    'page.categories.popupNotifications.creationFailed': `Category creation failed`,
    'page.categories.popupNotifications.created': `Category created`,
    'page.categories.actions.createSubcategory': `Create subcategory`,
    'page.categories.actions.createCategory': `Create category`,
    'page.categories.blockList.categoryTreeSearch': `Category tree search`,
    'page.categories.blockList.placeholder.createNewRootCategories': `Create new root categories`,
    'page.categories.blockCard.counter.products': `Products and kits`, // Окончание не изменяется
    'page.categories.blockCard.counter.renters': `Counterparties`, // Окончание не изменяется
    'page.categories.blockCard.counter.subcategories': `Subcategories`, // Окончание не изменяется
    'page.categories.blockCard.counter.productsInSubcategories': `Products and kits in subcategories`, // Окончание не изменяется
    'page.categories.blockCard.counter.rentersInSubcategories': `Counterparties in subcategories`, // Окончание не изменяется
    'page.settings.pageTitle': `Page title`,
    'page.settings.sectionTree.basic': `Basic`,
    'page.settings.sectionTree.users': `Users`,
    'page.settings.sectionTree.baProfile': `Account profile`,
    'page.settings.sectionTree.baProfile.subsection.description': `Description`,
    'page.settings.sectionTree.baProfile.subsection.contactPerson': `Contact person`,
    'page.settings.sectionTree.customFields': `Product fields`,
    'page.settings.sectionTree.pricingSchemes': `Pricing schemes`,
    'page.settings.sectionTree.modules': `Modules`,
    'page.basic.basicSettings': `Basic settings`,
    'page.basic.TimeGapBeforeGivaway': `Time gap before givaway`,
    'page.basic.TimeGapAfterReturn': `Time gap after return`,
    'page.basic.EditingBaseSettings': `Editing base settings`,
    'page.basic.MainSettingsCouldNotBeChanged': `Main settings could not be changed`,
    'page.basic.BasicSettingsChanged': `Basic settings changed`,
    'page.basic.notifications.failedToGetBasicSettings': `Failed to load basic settings`,
    'page.basic.legalCountry.help': `The main country of the business, used to pre-fill the country in the details of the company and contractors`,
    'page.basic.baseCurrency.help': `Используется только для отображения знака и наименования валюты, не имеет привязки к реальным курсам`,
    'page.basic.timezoneOffset.help': `Смещение времени относительно UTC. Например, смещение для Москвы +3:00. Позволяет корректно отображать время при составлении писем с уведомлениями сотрудников, в остальных случаях используется часовой пояс компьютера пользователя`,
    'page.basic.shiftLength.help': `Используется для пересчета числа смен при изменении периода аренды`,
    'page.basic.shiftCountRounding.help': `The algorithm for rounding the number of shifts. It is used to determine how many shifts correspond to a time period. Shifts are rounded to an integer or a step of the price scheme of the position`,
    'page.basic.requiredTimeIndentBetweenElements.help': `Используется для резервирования дополнительного времени между обязательствами аренды на осуществление физического возврата и выдачи. При создании и редактировании обязательств дополнительно проверяется доступность в этом периоде до начала и после завершения аренды`,
    'page.basic.allowConcurrentOperations.help': `If prohibited, the system will not allow to create an operation in a project (as well as delivery and template), if another user is already working there with the operation form`,
    'page.basic.includeBookedElementsInProjectSum.help': `Если включено, то в стоимость проекта и поставки дополнительно включаются обязательства в статусе Бронь и Бронь СА соответственно, а также при расчете долга арендатора учитываются заказы в статусе Бронь.`,
    'page.basic.defaultTaxRate.help': `The tax rate that is entered into the project creation form by default, helps to fill out the form faster`,
    'page.basic.taxBaseType.help': `The algorithm for obtaining the amount of tax based on the cost of the project. When choosing "Cost before taxes", the tax is taken directly as a percentage of the cost. When choosing "Full cost after tax", such a tax is selected that would make up the desired percentage of the total cost (the amount of the cost before taxes and tax)`,
    'page.basic.storefrontAccessToken.help': `Токен, используемый для получения доступа к АПИ для взаимодействия со стороны модуля витрины вашего интернет магазина. При обновлении токена, доступ по старому токену не предоставляется`,
    'page.basic.includePlannedWorkPlanningsInProjectSum.help': `Если включено, то в стоимость проекта дополнительно включаются работы в статусе Запланированы. Иначе включаются только работы в статусах В работе и Завершены`,
    'page.basic.contractValidationType.help': `Requirements for completing the contract data. Until they are completed, the project cannot be transferred to the "In progress" state, and the order cannot be transferred to the "Rent" state. Projects that are already in the "In progress" status and do not meet the requirements are automatically transferred to the "Coordination" state`,
    'page.basic.includeTaxInExternalExpenseSum.help': `The setting sets the default value for the tax accounting switch when creating new costs. It only affects the position of the switch "Taxable" when opening the creation form.`,
    'page.basic.calendarAccessToken.help': `Токен, используемый для выгрузки календарных данных в формате iCal. При обновлении токена, доступ по старому токену не предоставляется`,
    'page.basic.defaultProjectTypeOfPricing.help': `The setting sets the default value in the Pricing Type field in the new project creation form`,
    'page.basic.defaultRentTime.help': `The time that is set by default as the beginning of the period when creating a new deal, as well as new plannings or works in the deal without the specified period. If the given time of day has not yet arrived today, the current day is taken as the date, otherwise the next one.`,
    'page.basic.defaultRentDuration.help': `It is used in the same way as the previous setting as the default period length`,
    'page.basic.pledgeCalculationMethodType.help': `The amount of the pledge is calculated as a percentage of the purchase price or carrying value of the instances. Canceled and finished plannings are not taken into account. If the renter has an unsecured limit, the required pledge is reduced by this amount.`,
    'page.basic.restrictIfInsufficientPledge.help': `Prohibits the givaways if the calculated pledge exceeds the deposited one. The corrections allows you to bypass this limitation.`,
    'page.settings.users.sectionTitle': `User list`,
    'page.settings.users.filter.role': `Role`,
    'page.popup.editRoles.popupLabel': `Roles editing`,
    'page.modal.question.cancelInvitation': `Cancel the invitation?`,
    'page.modal.question.removeUser': `Remove all user roles in current company? After this, user will no longer be the member of it`,
    'page.modal.modalTitle': `Invitation a user to the company`,
    'page.popupNotifications.invitationSendingFailed': `Failed to send the invitation`,
    'page.popupNotifications.invitationSent': `Invitation sent`,
    'page.popupNotifications.invitationCanceled': `Invitation canceled`,
    'page.popupNotifications.getListFailed': `Failed to load the list of users`,
    'page.popupNotifications.rolesUpdateFailed': `Failded to update user roles`,
    'page.popupNotifications.rolesUpdated': `User roles updated`,
    'page.actions.inviteUser': `Invite user`,
    'page.form.invitation.placeholder.chooseRoles': `Select roles`,
    'page.form.invitation.userAuthorities': `User authorities`,
    'page.settings.baProfile.field.fullName.longTitle': `Full official name`,
    'page.popupNotifications.updated': `Company account updated`, // Тут чуть текст изменить
    'page.popupNotifications.updateFailed': `Company account update failed`,
    'page.form.update.formTitle': `Company account editing`,
    'page.settings.modules.disableButtonLabel': `Отключить`,
    'page.settings.modules.enableButtonLabel': `Включить`,
    'page.settings.modules.enabledStatus': `Подключен`,
    'page.settings.modules.disabledStatus': `Отключен`,
    'page.settings.modules.unavailabledStatus': `Недоступен в тарифе`,
    'page.settings.modules.logisticsModuleName': `Модуль логистики`,
    'page.settings.modules.logisticsModuleDescription': `The logistics module allows you to control the geography of projects and all aspects of transportation between facilities. The vehicle fleet, its load capacity, dimensions and other details are taken into account. Each shipment is tracked with the ability to automatically generate a bill of lading. A database of locations is also supported. You can store contact information, attachments, specifications, transportation and project history for each facility.`, // Для русского было укорочение описания, а для англ не было, мб и не надо
    'page.settings.modules.crewModuleName': `Crew module`,
    'page.settings.modules.crewModuleDescription': `The crew module allows you to automate the planning and calculation of work performed by employees on a project. Keep a database of employees, specify their rates and specialties, and then assign employees to vacancies quickly and without errors according to their schedule and skills.`,
    'page.settings.modules.statisticsModuleName': `Модуль статистики`,
    'page.settings.modules.statisticsModuleDescription': `The statistics module allows you to build analytical reports, based on which you can see the big picture of the company's work. For example, the most in-demand products and categories are what needs to be purchased, and vice versa - equipment that is idle. Payment receipt schedule, list of debtors and much more. Reports are calculated dynamically for each user request at any time. Each report can be studied in a graphical representation - in the form of a graph or chart, or detailed data can be exported to Excel or PDF formats. The set of reports is constantly being updated, and you can also order your own report according to your requirements, it will be available only to your company.`, // Для русского было укорочение описания, а для англ не было, мб и не надо
    'page.settings.modules.subrentModuleName': `Модуль субаренды`,
    'page.settings.modules.subrentModuleDescription': `The subrent module allows you to control the rental of various products from suppliers for subsequent sublease. A new type of counterparty, the supplier, becomes available. And a new kind of activity framework is delivery. The delivery works like a project, only in a mirror way - it describes the interaction with the supplier whose products will be sublet. After booking an item in the delivery, its nomenclature will be available for booking and subletting. After the completion of the project, you will be able to automatically determine the worked shifts for each delivery item for settlement with the supplier.`, // Для русского было укорочение описания, а для англ не было, мб и не надо
    'page.settings.modules.expensesModuleName': `Модуль затрат`,
    'page.settings.modules.expensesModuleDescription': `Модуль затрат позволяет вести учет произвольных затрат по проекту. Например, расходов на такси или гостиницу в выездном проекте, другой пример - учет страховки и расходников. Доступна внешняя стоимость (для клиента) и внутренняя (себестоимость).`,
    'page.settings.modules.offersModuleName': `Модуль смет`,
    'page.settings.modules.offersModuleDescription': `Модуль смет позволяет внутри основного проекта разрабатывать сметы, согласовывать их с клиентом, после чего устанавливать стоимость проекта на основе одной или нескольких смет.`,
    'page.settings.modules.ordersModuleName': `Модуль заказов`,
    'page.settings.modules.ordersModuleDescription': `The module allows you to work with ordinary one-time customer orders in the simplest and fastest possible mode, without creating complex projects and operations.`,
    'page.settings.modules.projectsModuleName': `Модуль проектов`,
    'page.settings.modules.projectsModuleDescription': `The module allows you to conduct complex long-term rental projects with partial disbursements, refunds and other operations. When the appropriate modules are enabled, several estimates can be kept in the project, costs can be taken into account and work can be planned.`,
    'page.settings.modules.enableModuleWarning': `Вы уверены, что хотите подключить модуль?`,
    'page.settings.modules.disableModuleWarning': `Вы уверены, что хотите отключить модуль?`,
    'page.settings.modules.enableModuleSuccessMessage': `Модуль подключен`,
    'page.settings.modules.enableModuleErrorMessage': `Не удалось подключить модуль`,
    'page.settings.modules.disableModuleSuccessMessage': `Модуль отключен`,
    'page.settings.modules.disableModuleErrorMessage': `Не удалось отключить модуль`,
    'page.settings.fields.tooltip.fieldTypeCompanyRestriction': `Currently using {0} of {1} total available fields of this type`,
    'page.settings.fields.tooltip.validations.shortNameAlreadyUsed': `Custom field short name already used`,
    'page.settings.extra.editingLabel': `Editing settings`,
    'page.settings.extra.popupNotifications.settingsUpdated': `Settings updated`, // Бывш. 'данные изменены'
    'page.userProfile.eventType.assignement': `Assignment`,
    'page.userProfile.eventType.lostOrBroken': `Loss or breakage`,
    'page.userProfile.eventType.newOrderFromStorefront': `Новый заказ с витрины`,
    'page.userProfile.pageTitle.currentUserProfile': `My profile`, // Когда просмотр профиля текущего пользователя
    'page.userProfile.pageTilte.otherUserProfile': `User profile`, // Когда просмотр профиля не текущего пользователя
    'page.userProfile.eventType.orderFromStorefrontCanceled': `Storefront order canceled`,
    'page.userProfile.modal.question.turnOffAllNotifications': `Are you sure, you want to turn off all notifications from RentRabbit?`,
    'page.userProfile.popupNotifications.getFailed': `Failed to load user profile`,
    'page.userProfile.popupNotifications.changed': `Notification settings changed`,
    'page.userProfile.popupNotifications.comfirmChangeEmail': `После смены email адреса на новый, вход будет невозможен до его подтверждения. Вы уверены, что хотите продолжить?`,
    'page.userProfile.tab.description.field.login': `Login`,
    'page.userProfile.tab.description.field.roles': `Roles`,
    'page.userProfile.tab.description.field.locale': `Locale`,
    'page.userProfile.tab.notifications.projectSelector.option.assignedToMe': `Only assigned to me`,
    'page.userProfile.tab.notifications.eventTypes': `Events`,
    'page.userProfile.tab.notifications.notificationsAboutOwnActions': `Notify on own actions`,
    'page.userProfile.tab.notifications.block.defaultSettings': `Default settings`,
    'page.userProfile.tab.notifications.block.globalSettings': `General settings`,
    'page.userProfile.tab.notifications.notifications': `Notifications`,
    'page.userProfile.form.notificationScheme.formTilte': `Notification settings`,
    'page.userProfile.form.notificationScheme.validation.fieldIsEmpty': `Field is empty`,
    'page.userProfile.form.notificationScheme.sendNotofications': `Send notifications`,
    'page.userProfile.form.notificationScheme.assignedToMe': `Assigned to me`,
    'page.userProfile.form.notificationScheme.placeholder.chooseTargetEventTypes': `Set event types you want to get notifications about`,
    'page.userProfile.form.notificationScheme.ownActions': `Own actions`,
    'page.userProfile.form.notificationScheme.notificationsSettingsByBusinessAccount': `Notofication settings by company`,
    'page.invitations.proceedButton': `Proceed`,
    'page.invitations.invitationTextRolesGranted': `Roles have been assigned to you`,
    'page.invitations.invitationMainText': `RentRabbit user sent you the invitation`,
    'page.invitations.acceptButton': `Accept`,
    'page.invitations.declineButton': `Decline`,
    'page.invitations.modalTitle': `Invitation`,
    'page.invitations.popupNotifications.invitationOutOfDate': `Invitation is out of date`,
    'page.invitations.popupNotifications.invitationAccepted': `Invitation accepted`,
    'page.help.helpCenter': `Help center`,
    'page.help.supportRequests': `Support requests`,
    'page.instances.instanceList': `Instance list`,
    'page.instances.createInstance': `Create instance`,
    'page.instances.hideDecomissioned': `Скрыть списанные`,
    'page.modal.valiationMessageNameOrCode': `Name or code already in use in current product`, // тут чуть переименовал!
    'page.instances.popupNotifications.creationFailed': `Product instance creation failed`,
    'page.instances.popupNotifications.created': `Product instance created`,
    'page.instances.popupNotifications.updateFailed': `Product instance update failed`,
    'page.instances.popupNotifications.updated': `Product instance updated`,
    'page.instances.popupNotifications.getListFailed': `Failed to load list of product instances`,
    'page.instances.popupNotifications.codeOrNameUsedInProduct': `Name already used in product`,
    'page.instances.popupNotifications.inventoryNumberUsedInProduct': `Official inventory number already used in company`,
    'page.cardPopup.currentAvailability': `Current availability`,
    'page.cardPopup.carryingValue': `Балансовая стоимость`,
    'page.form.formTitle.edit': `Instance editing`,
    'page.form.formTitle.create': `Instance creation`,
    'page.variants.variantList': `Variant list`,
    'page.variants.createVariant': `Create variant`,
    'page.variants.addCustomField': `Add field`,
    'page.modal.unlinkFieldFromProductAndLinkToEachVariant': `Unlink field from product and link to each variant?`,
    'page.modal.removeField': `Remove field?`,
    'page.variants.popupNotifications.variantUpdated': `Product variant updated`,
    'page.variants.popupNotifications.variantsUpdated': `Product variants updated`,
    'page.variants.popupNotifications.getListFailed': `Failed to load list of product variants`,
    'page.variants.popupNotifications.created': `Product variant created`,
    'page.variants.cardPopup.fieldValueByProduct': `By product`,
    'page.variants.cardPopup.features': `Features`,
    'page.variants.form.formTitle.edit': `Variant editing`,
    'page.variants.form.formTitle.create': `Creating a new variant`,
    'page.variants.form.validations.nameAlreadyUsed': `Наименование варианта уже использовано в продукте`,
    'page.variants.form.validations.externalCodeAlreadyUsed': `Артикул уже использован`,
    'page.variants.form.variantFeatures': `Характеристики варианта`,
    'page.companyRegistration.label': `Company registration`,
    'page.companyRegistration.field.companyName': `Company name`,
    'page.companyRegistration.field.industry': `Industry`,
    'page.companyRegistration.field.demoData': `Demo data`,
    'page.companyRegistration.fieldOption.fillInTheDemoData': `Fill with demo data`,
    'page.companyRegistration.fieldOption.doNotFillIn': `Leave blank`,
    'page.companyRegistration.pleaseFillInAllTheFields': `Please fill all the fields`,
    'page.companyRegistration.button.continue': `Continue`,
    'page.companyRegistration.popupNotifications.failedToRegisterBusinessAccount': `Failed to register the company`,
    'page.companyRegistration.allRightsReserved': `All rights reserved`,
    'page.companyRegistration.validations.invalidCompanyName': `Incorrect name`,
    'page.companyRegistration.leaseAgreementLegalString.start': `By clicking the button, you agree to`,
    'page.companyRegistration.leaseAgreementLegalString.end': `the terms of the lease`,
    'page.companyRegistration.industryFieldOption.av': `Audio and video equipment`,
    'page.companyRegistration.industryFieldOption.sportInventory': `Sports equipment`,
    'page.companyRegistration.industryFieldOption.clothes': `Clothing and accessories`,
    'page.companyRegistration.industryFieldOption.props': `Props`,
    'page.companyRegistration.industryFieldOption.events': `Event organization`,
    'page.companyRegistration.industryFieldOption.hr': `Human resources`,
    'page.companyRegistration.industryFieldOption.construction': `Construction equipment`,
    'page.companyRegistration.industryFieldOption.transport': `Transport rental`,
    'page.companyRegistration.industryFieldOption.other': `Other industry`,
    'page.newOperation.elements.action.copy.tooltip': `<p><strong>Создать дубликат</strong>  </p><p>Добавить продукты со склада и сформировать по ним новое обязательство с аналогичными условиями аренды</p>`, // Кнопка создания дубликата в списке обязательств
    'page.newOperation.elements.action.edit.tooltip': `<p><strong>Редактировать</strong>  </p><p>Уточнить условия аренды и количество экземпляров обязательства</p>`, // Кнопка редактирования в списке обязательств
    'page.newOperation.elements.action.delete.tooltip': `<p><strong>Удалить</strong>  </p><p>Убрать обязательство из операции</p>`, // Кнопка удаления в списке обязательств
    'page.newOperation.elements.action.moveElementToKit.tooltip': `<p><strong>Поместить в набор</strong>  </p><p>Поместить обязательства из состава операции в текущий набор</p>`, // Кнопка перемещения обязательства в набор в списке обязательств
    'page.newOperation.useCommonParams.tooltip': `<p><strong>Общий параметр</strong>  </p><p>Задано единое значение параметра аренды для всех обязательств данной операции</p>`, // Попап над замочками в создании операции: Общие (серый замок)
    'page.newOperation.useIndividualParams.tooltip': `<p><strong>Индивидуальные параметры</strong>  </p><p>Заданы различные значения параметра аренды для обязательств данной операции</p>`, // Попап над замочками в создании операции: Индивидуальные (желтый замок)
    'page.historyElement.useIndividualParams.tooltip': `<p><strong>Индивидуальные параметры</strong>  </p><p>Заданы различные значения параметра аренды обязательств данной операции</p>`,
    'page.settings.customFields.field.origin': `Origin`,
    'page.settings.customFields.field.typeCode': `Data type`,
    'page.settings.customFields.field.typeCode.placeholder': `Select type`,
    'page.settings.customFields.field.multipleValues': `Multiple values`,
    'page.settings.customFields.field.entitiesLinkedNumber': `Linked number`,
    'page.settings.customFields.standardType': `Standard`,
    'page.settings.customFields.notStandardType': `Пользовательское`,
    'page.settings.customFields.hideStandardFields': `Hide standard fields`,
    'page.settings.customFields.action.create': `Create`,
    'page.settings.customFields.action.create.tooltip': `<p>Create</p>`,
    'page.settings.customFields.action.edit.tooltip': `<p>Edit</p>`,
    'page.settings.customFields.action.delete.tooltip': `<p>Delete</p>`,
    'page.settings.customFields.modal.create.title': `Create custom field`,
    'page.settings.customFields.modal.edit.title': `Edit custom field`,
    'page.settings.customFields.loadEntitiesErrorMessage': `Не удалось загрузить список полей`,
    'page.settings.customFields.createSuccessMessage': `Поле успешно создано`,
    'page.settings.customFields.updateSuccessMessage': `Поле успешно изменено`,
    'page.settings.customFields.deleteSuccessMessage': `Поле удалено`,
    'page.settings.customFields.deleteErrorMessage': `Поле не удалено`,
    'page.settings.customFields.deleteConfirmMessage': `Вы действительно хотите удалить поле?`,
    'page.settings.customFields.editCancelConfirmMessage': `Прервать редактирование?`,
    'page.settings.pricingSchemes.deleteConfirmMessage': `Вы действительно хотите удалить ценовую схему?`,
    'usersBA.Roles': `Роли`, // Роли
    'usersBA.Roles.Agent': `Агент`, // Агент
    'usersBA.Roles.Admin': `Администратор`, // Администратор
    'usersBA.Roles.Placeholder': `Выбрать роль` // Выбрать роль
};
