import React from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../../shared/reducers';
import {BasePage, IBasePageProps} from '../../../components/page/BasePage';
import {IPageHeaderProps} from '../../../components/pageHeader/pageHeader';
import {
    createOrEditSimpleOrder,
    resetOperation, setCreationError,
    setCreationInProgress,
    startNewSimpleOrderOperation, updateElementsContext
} from "./reducers/operationForm.reducer";
import {OrderElements} from "./orderModule/orderElements";
import {OrderPageHeaderNavigationBlock} from "./orderModule/orderPageHeaderNavigationBlock";
import {ProjectInfoRead, ProjectStateCodeEnum} from "../../../server";
import {Spin} from "../../../components";
import {resetBusinessAccountIndicators} from "../../../shared/reducers/businessAccountIndicators.reducer";
import './OperationFormPage.less';
import {replace} from "connected-react-router";
import {showNotification} from "../../../components/notification/showNotification";
import {
    ordersModuleEnabledSelector,
    projectsModuleEnabledSelector,
    subrentModuleEnabledSelector
} from "../../../shared/reducers/businessAccountPreferences.reducer";
import {Redirect} from "react-router";
import {businessAccountIdSelector} from "../../../shared/reducers/system.reducer";
import {OrderCancelButton} from "./orderModule/comonents/orderCancelButton/orderCancelButton";
import {InterfaceBlockCode} from "../../../components/pageHeader/components/InfoSelectDropdown/utils/constants";
import {InfoSelectDropdown} from "../../../components/pageHeader/components/InfoSelectDropdown/InfoSelectDropdown";
import {getServerError} from "../../../shared/util/utils";

interface IProps extends StateProps, DispatchProps, IBasePageProps {
}

interface IState {}

class Component extends BasePage<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidMount() {
        super.componentDidMount();
        if (!this.props.mnemoKey){
            this.props.startNewSimpleOrderOperation();
        }
    }

    getPageHeaderProps(): IPageHeaderProps {
        let {renterId, renterShortName} = this.props;

        return {
            NavigationBlock: <OrderPageHeaderNavigationBlock data={{
                orderName: 'Новый заказ',
                counterpartyId: renterId,
                counterpartyName: renterShortName,
                stateCode: ProjectStateCodeEnum.DRAFT
            }}/>,
            rightBlock: <>
                <OrderCancelButton creationMode={true} />
                <InfoSelectDropdown interfaceBlockCode={InterfaceBlockCode.BA_ORDERS_ORDER_CARD} />
            </>
        };
    }

    createNewOrder = async () => {
        this.props.setCreationInProgress(true);
        this.props.setCreationError(undefined);

        let project:ProjectInfoRead|undefined;
        try{
            project = await this.props.createSimpleOrder() as any;
        }catch(error:unknown){
            let err = getServerError(error);
            this.props.setCreationError(err);
            window.scrollTo(0, 0);
            try {
                await this.props.updateElementsContext();
                showNotification('success', 'Контекст обновлен');
            } catch (error: unknown) {
                showNotification('error', 'Контекст не обновлен');
            }
        }
        if(project){
            this.props.resetOperation();
            this.props.resetBusinessAccountIndicators();
            this.props.replace(`/${project.businessAccountId}/projects/simpleOrders/${project.id}?tab=description`)
            showNotification('success', 'Заказ создан');
        }
        this.props.setCreationInProgress(false);
    };

    renderContent() {
        const {ordersModuleEnabled, businessAccountId} = this.props;

        if(!ordersModuleEnabled){
            return <Redirect to={`/${businessAccountId}/operation`} />;
        }

        return (
            <Spin spinning={this.props.creationInProgress}>
                <OrderElements createOperation={this.createNewOrder} />
            </Spin>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        renterId: storeState.operationForm.renterId,
        renterShortName: storeState.operationForm.renterShortName,
        mnemoKey: storeState.operationForm.mnemoKey,
        businessAccountId: businessAccountIdSelector(storeState),
        projectsModuleEnabled: projectsModuleEnabledSelector(storeState),
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
        ordersModuleEnabled: ordersModuleEnabledSelector(storeState),
        creationInProgress: storeState.operationForm.creationInProgress
    };
};

const mapDispatchToProps = {startNewSimpleOrderOperation, resetBusinessAccountIndicators, resetOperation, createSimpleOrder: createOrEditSimpleOrder, replace, setCreationError, setCreationInProgress, updateElementsContext};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const NewOrderPage = connect(mapStateToProps, mapDispatchToProps)(Component);
