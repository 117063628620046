import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import classNames from 'classnames';
import './linkButton2.less';

interface LinkButton2Props {
    label?: ReactNode;
    icon?: ReactNode;
    style?: ButtonProps['style'];
    onClick?: ButtonProps['onClick'];
    disabled?: ButtonProps['disabled'];
    className?: string;
}

export const LinkButton2 = ({ label, icon, style, onClick, disabled, className }: LinkButton2Props) => {
    return (
        <Button
            type={'link'}
            style={{ textDecoration: 'underline', ...style }}
            className={classNames('rr-link-btn-2', className)}
            onClick={onClick}
            disabled={disabled}
        >
            {icon}
            {label}
        </Button>
    );
};
