import React from 'react';
import {Button, Dropdown, Icon, Popover, Tooltip} from 'antd';
import {
    OperationAvailableStatesResponseObj,
    OperationTypeCodeEnum,
    RentActivityFrameTypeCodeEnum,
    RentStateCodeEnum,
} from '../../../../../server/api';
import {getRentElementStateByCode, localize} from '../../../../../localization';
import {IRootState} from '../../../../../shared/reducers';
import {connect} from 'react-redux';
import {serverApi} from '../../../../../server';
import {AxiosResponse} from 'axios';
import {ALL_OPERATION_TYPES, ALL_RENT_STATE_CODES} from '../../../../../types';
import {showNotification} from '../../../../../components/notification/showNotification';
import {RoundButton} from '../../../../../components';
import {canCreateCorrectionOperation} from '../../../../../shared/util/permissionUtils';

interface OperationTypeSelectButtonProps extends StateProps, DispatchProps {
    onChangeOperationType: (code: OperationTypeCodeEnum, correctionCode?: RentStateCodeEnum | undefined) => void;
    projectId: number;
    activityFrameType: RentActivityFrameTypeCodeEnum;
    presetData?: OperationAvailableStatesResponseObj;
    tooltipTitle?: string;
    disabled?: boolean;
    pledgeRequired?: boolean;
}

interface Props extends OperationTypeSelectButtonProps, StateProps, DispatchProps {}

interface State {
    loading: boolean;
    dropdownVisible: boolean;
    correctionDropdownVisible: boolean;
    availableCorrectionTargetStateCodes?: RentStateCodeEnum[];
    availableOperationTypeCodes?: OperationTypeCodeEnum[];
}

export class _OperationTypeSelectButton extends React.Component<Props, State> {
    public static defaultProps = {
        activityFrameType: RentActivityFrameTypeCodeEnum.PROJECT,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            dropdownVisible: false,
            correctionDropdownVisible: false,
        };
    }

    listAvailableOperationTypes = async () => {
        const { presetData } = this.props;

        if (presetData) {
            this.setState({
                availableOperationTypeCodes: presetData.operationTypeCodes,
                availableCorrectionTargetStateCodes: presetData.correctionTargetStateCodes,
                loading: false,
                dropdownVisible: true,
            });
        } else {
            let result: AxiosResponse<OperationAvailableStatesResponseObj> | undefined;
            try {
                this.setState({
                    availableOperationTypeCodes: undefined,
                    availableCorrectionTargetStateCodes: undefined,
                    loading: true,
                    dropdownVisible: false,
                });
                result = await serverApi.listAvailableOperationTypes(this.props.businessAccountId, {
                    activityFrameId: this.props.projectId,
                    activityFrameType: this.props.activityFrameType,
                });
            } catch (err) {
                // Ошибка получения доступных типов
                this.setState({
                    loading: false,
                    dropdownVisible: false,
                });
                showNotification('error', 'Не удалось загрузить список доступных типов операций');
            }
            if (result) {
                // Получены типы
                this.setState({
                    availableOperationTypeCodes: result.data.operationTypeCodes,
                    availableCorrectionTargetStateCodes: result.data.correctionTargetStateCodes,
                    loading: false,
                    dropdownVisible: true,
                });
            }
        }
    };

    generateButtons = () => {
        let availableTypeCodes = this.state.availableOperationTypeCodes || [];
        let buttons = ALL_OPERATION_TYPES.filter((type) => availableTypeCodes.includes(type.code))
            .filter(
                (type) =>
                    !(
                        type.code === OperationTypeCodeEnum.CORRECT &&
                        (!this.state.availableCorrectionTargetStateCodes || !this.state.availableCorrectionTargetStateCodes.length)
                    )
            )
            .map((type) => {
                let disabled = false;
                let tooltipMessage:string|undefined;
                if(type.code === OperationTypeCodeEnum.RENT && this.props.pledgeRequired){
                    disabled = true;
                    tooltipMessage = 'Требуется внести залог';
                }

                return type.code !== OperationTypeCodeEnum.CORRECT ? (
                    <Tooltip title={tooltipMessage}>
                        <Button
                            disabled={disabled}
                            block
                            onClick={() => {
                                this.setState({ dropdownVisible: false, correctionDropdownVisible: false });
                                this.props.onChangeOperationType(type.code);
                            }}
                        >
                            <Icon className={'rr-operation-status-color-' + type.colorScheme} component={type.icon} />
                            <span>{localize(type.localizationCode)}</span>
                        </Button>
                    </Tooltip>
                ) : (
                    this.createCorrectionPopover()
                );
            });

        return <div className={'ant-popover-inner rr-grid-actions-popover-content'}>{buttons}</div>;
    };

    createCorrectionPopover = () => {
        let availableTypeCodes = this.state.availableCorrectionTargetStateCodes || [];
        let buttons = ALL_RENT_STATE_CODES.filter((type) => availableTypeCodes.includes(type.code)).map((type) => {
            return (
                <Button
                    block
                    onClick={() => {
                        this.setState({ dropdownVisible: false, correctionDropdownVisible: false });
                        this.props.onChangeOperationType(OperationTypeCodeEnum.CORRECT, type.code);
                    }}
                >
                    <div className={`rr-dot rr-status-bg-${type.code}`}></div>
                    <span>{getRentElementStateByCode(type.code)}</span>
                </Button>
            );
        });

        let correctionType = ALL_OPERATION_TYPES.find((type) => type.code === OperationTypeCodeEnum.CORRECT);
        return (
            correctionType && (
                <Popover
                    overlayClassName={'rr-operationForm-type-select-buttons-group__correction-popover-content'}
                    visible={this.state.correctionDropdownVisible}
                    onVisibleChange={this.onCorrectionDropdownVisibleChange}
                    content={<div className={'rr-grid-actions-popover-content'}>{buttons}</div>}
                    placement={'leftBottom'}
                    trigger={'hover'}
                    overlayStyle={{ width: 300 }}
                >
                    <Button block>
                        <Icon className={'rr-operation-status-color-' + correctionType.colorScheme} component={correctionType.icon} />
                        <span>{localize(correctionType.localizationCode)}</span>
                    </Button>
                </Popover>
            )
        );
    };

    onDropdownVisibleChange = (visible: boolean) => {
        setTimeout(
            () => {
                this.setState({
                    dropdownVisible: visible,
                });
            },
            visible ? 0 : 100
        );
    };

    onCorrectionDropdownVisibleChange = (visible: boolean) => {
        this.setState({
            correctionDropdownVisible: visible,
        });
    };

    onListAvailableOperationsButtonClick = (e) => {
        if (!this.state.dropdownVisible) {
            this.listAvailableOperationTypes();
        }
    };

    render = () => {
        const { disabled, tooltipTitle } = this.props;

        // Дизейблед атрибут не работает внутри тултипа (antd)
        const disabledStyles = disabled
            ? {
                  color: '#ffffff',
                  background: '#c4c5d6',
                  borderColor: '#c4c5d6',
                  cursor: 'default',
              }
            : {};

        const Button = (
            <RoundButton
                disabled={this.props.disabled}
                icon={'plus'}
                colorScheme={'success'}
                onClick={disabled ? () => {} : this.onListAvailableOperationsButtonClick}
                style={{
                    ...disabledStyles,
                }}
            >
                <span>Создать операцию</span>
            </RoundButton>
        );

        return (
            <Dropdown
                overlayStyle={{ width: 300 }}
                overlay={this.generateButtons()}
                placement="bottomLeft"
                trigger={['click']}
                visible={this.state.dropdownVisible}
                onVisibleChange={this.onDropdownVisibleChange}
            >
                {/* Необходимо обернуть кнопку в div чтобы тултип работал (antd) */}
                {tooltipTitle ? (
                    <Tooltip title={tooltipTitle}>
                        <div>{Button}</div>
                    </Tooltip>
                ) : (
                    Button
                )}
            </Dropdown>
        );
    };
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        userPermissions: storeState.permissions.permissions,
        canCreateCorrectionOperation: canCreateCorrectionOperation(storeState.permissions.permissions),
    };
};

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationTypeSelectButton = connect(mapStateToProps, mapDispatchToProps)(_OperationTypeSelectButton);
