import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {KitInfoRead, serverApi} from "../../../../../server";
import {useAppSelector} from "../../../../../store/hooks";
import {businessAccountIdSelector} from "../../../../../shared/reducers/system.reducer";
import {formatMoneyWithFixedDecimalScale} from "../../../../../shared/util/formatMoney";
import {Spin} from "../../../../../components";
import {canViewFinancialDataSelector} from "../../../../../shared/reducers/permissions.reducer";
import {KitPageTabsEnum, ProductPageTabsEnum} from "../../../../../shared/constants/tabEnums";
import './kitComponentsPopoverContent.less';

interface KitComponentsPopoverContentProps {
    kitId: number;
    onDataLoaded?: () => void;
}

export const KitComponentsPopoverContent = ({kitId, onDataLoaded}: KitComponentsPopoverContentProps) => {

    const canViewFinancialData = useAppSelector(canViewFinancialDataSelector);
    const businessAccountId = useAppSelector(businessAccountIdSelector);
    const [kit, setKit] = useState<KitInfoRead | undefined>();
    const [isError, setIsError] = useState(false);

    const loadData = async () => {
        try {
            const query = await serverApi.getKitById(businessAccountId, kitId);
            setKit(query.data);
        } catch (e: unknown) {
            setIsError(true);
        }
        onDataLoaded?.();
    };

    useEffect(() => {
        void loadData();
    }, []);

    if (!kit) {
        return (
            <div style={{height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {
                    !isError ? <Spin delay={0}/> : <span style={{color: '#f4516c'}}>Ошибка загрузки</span>
                }
            </div>
        );
    }

    return (
        <div className={'rr-kitComponents__popover-content'}>
            <div>
                {
                    kit?.members.map((member) => {
                        const title = `${member.productShortName}${member.variantName ? (': ' + member.variantName) : ''}`
                        return (
                            <div className={'data-row'}>
                                <div>{member.instanceCount}</div>
                                <div>
                                    <div>
                                        {
                                            !member.variantId && (
                                                <Link title={title} className={'product-link'} to={`/${businessAccountId}/inventory/products/${member.productId}?tab=${ProductPageTabsEnum.DESCRIPTION}`}>
                                                    <div className={'product-name'}>{member.productShortName}</div>
                                                </Link>
                                            )
                                        }
                                        {
                                            member.variantId && (
                                                <Link title={title} className={'product-variant-link'} to={`/${kit.businessAccountId}/inventory/products/${member.productId}?tab=description&variantId=${member.variantId}`}>
                                                    <div className={'product-name'}>{member.productShortName}</div>
                                                    <div className={'variant-name'}>: {member.variantName}</div>
                                                </Link>
                                            )
                                        }
                                    </div>
                                </div>
                                {canViewFinancialData &&
                                    <div>{formatMoneyWithFixedDecimalScale(member.finalInstancePrice)}</div>}
                            </div>
                        );
                    })
                }
                {
                    canViewFinancialData && (
                        <div className={'result-row'}>
                            <div></div>
                            <div>Итоговая цена за смену:</div>
                            <div>{formatMoneyWithFixedDecimalScale(kit.finalTotalPrice)}</div>
                        </div>
                    )
                }
            </div>
            <div>
                <Link to={`/${businessAccountId}/inventory/kits/${kit?.id}?tab=${KitPageTabsEnum.KITS}`}>Подробнее</Link>
            </div>
        </div>
    );
}
